import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LoginDto } from '../models/login-dto';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private token: string;
  private tokenExpire: Date;
  // private refreshToken: string;
  private refreshTokenExpire: Date;

  constructor(private userService: UserService) {
    let cached = localStorage.getItem('userToken');
    if(cached){
      this.readToken(cached);
      if(!this.isAccessTokenExpired())
      {
        this.isLoggedIn$.next(true);
      }
      else {
        this.logout();
      }
    }
  }

  public get Token(): string {
    return this.token;
  }

  /**
   * Check if the current user token is expired
   * @returns true if token expired
   */
  public isAccessTokenExpired(): boolean {
    if (this.token && this.tokenExpire) {
      return this.tokenExpire < new Date();
    }
    return true;
  }

  /**
   * Check if the refresh token is expired
   * @returns true if refreshToken expired
   */
  public NeedRefreshToken(): boolean {
    if (this.refreshTokenExpire) {
      return this.refreshTokenExpire < new Date();
    }
    return true;
  }

  /**
   * Call the API to try and log the user with the given credentials
   * @param param Username Password DTO object
   * @returns true if logged successfully
   */
  public login(param: LoginDto): Observable<boolean> {
    return this.userService.apiUserLoginPost$Json({ body: param }).pipe(
      switchMap((data) => {
        if (data && data.token) {
          this.readToken(data.token);
          localStorage.setItem('userToken', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          this.isLoggedIn$.next(true);
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  /**
   * Call the API to renew the authentication token for the current User
   * @returns true if the token has been refreshed
   */
  public renewToken(): Observable<boolean> {
    return this.userService.apiUserRenewTokenPost$Json().pipe(
      switchMap((data) => {
        if (data && data.token) {
          this.readToken(data.token);
          localStorage.setItem('userToken', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          this.isLoggedIn$.next(true);
          return of(true);
        } else {
          this.logout();
          return of(false);
        }
      })
    );
  }

  /**
   * Log out the user and clean local cache.
   */
  public logout(): void {
    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
    this.token = null;
    // this.refreshToken = null;
    this.tokenExpire = null;
    this.refreshTokenExpire = null;
    this.isLoggedIn$.next(false);
  }

  /**
   * Parse the token from the API, and save value in the service
   * @param jwt the JWT response from the API
   */
  public readToken(jwt: string): void {
    let parsed = this.parseJwt(jwt);
    //Timespan C# in seconds to JS Date => value * 1000 to get milliseconds, then new Date with this milliseconds value
    this.tokenExpire = new Date(parsed['exp'] * 1000);
    this.refreshTokenExpire = new Date(parsed['exp'] * 900)
    this.token = jwt;
  }

  /**
   * Parse the JWT token generated from the API
   * @param token token issued
   * @returns token in dictionnary form
   */
  private parseJwt(token: string): any {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
