/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FormDuplicationDto } from '../models/form-duplication-dto';
import { FormHistoriesDto } from '../models/form-histories-dto';
import { FormHistoryDto } from '../models/form-history-dto';
import { FormInfoDto } from '../models/form-info-dto';
import { SituationTravailWithDangersDto } from '../models/situation-travail-with-dangers-dto';
import { TypeForm } from '../models/type-form';

@Injectable({
  providedIn: 'root',
})
export class FormService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiFormHistoryGet
   */
  static readonly ApiFormHistoryGetPath = '/api/Form/History';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormHistoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryGet$Plain$Response(params?: {
    typeForms?: Array<TypeForm>;
    dateIntervention?: string;
    nature?: string;
    nomSociete?: string;
    idDonneurOrdre?: number;
    idLieuIntervention?: number;
  }): Observable<StrictHttpResponse<FormHistoriesDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormHistoryGetPath, 'get');
    if (params) {
      rb.query('typeForms', params.typeForms, {});
      rb.query('dateIntervention', params.dateIntervention, {});
      rb.query('nature', params.nature, {});
      rb.query('nomSociete', params.nomSociete, {});
      rb.query('idDonneurOrdre', params.idDonneurOrdre, {});
      rb.query('idLieuIntervention', params.idLieuIntervention, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormHistoriesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormHistoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryGet$Plain(params?: {
    typeForms?: Array<TypeForm>;
    dateIntervention?: string;
    nature?: string;
    nomSociete?: string;
    idDonneurOrdre?: number;
    idLieuIntervention?: number;
  }): Observable<FormHistoriesDto> {

    return this.apiFormHistoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormHistoriesDto>) => r.body as FormHistoriesDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormHistoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryGet$Json$Response(params?: {
    typeForms?: Array<TypeForm>;
    dateIntervention?: string;
    nature?: string;
    nomSociete?: string;
    idDonneurOrdre?: number;
    idLieuIntervention?: number;
  }): Observable<StrictHttpResponse<FormHistoriesDto>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormHistoryGetPath, 'get');
    if (params) {
      rb.query('typeForms', params.typeForms, {});
      rb.query('dateIntervention', params.dateIntervention, {});
      rb.query('nature', params.nature, {});
      rb.query('nomSociete', params.nomSociete, {});
      rb.query('idDonneurOrdre', params.idDonneurOrdre, {});
      rb.query('idLieuIntervention', params.idLieuIntervention, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormHistoriesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormHistoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryGet$Json(params?: {
    typeForms?: Array<TypeForm>;
    dateIntervention?: string;
    nature?: string;
    nomSociete?: string;
    idDonneurOrdre?: number;
    idLieuIntervention?: number;
  }): Observable<FormHistoriesDto> {

    return this.apiFormHistoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormHistoriesDto>) => r.body as FormHistoriesDto)
    );
  }

  /**
   * Path part for operation apiFormHistoryByCompanyGet
   */
  static readonly ApiFormHistoryByCompanyGetPath = '/api/Form/HistoryByCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormHistoryByCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryByCompanyGet$Plain$Response(params?: {
    idCompany?: number;
  }): Observable<StrictHttpResponse<Array<FormHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormHistoryByCompanyGetPath, 'get');
    if (params) {
      rb.query('idCompany', params.idCompany, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FormHistoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormHistoryByCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryByCompanyGet$Plain(params?: {
    idCompany?: number;
  }): Observable<Array<FormHistoryDto>> {

    return this.apiFormHistoryByCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormHistoryDto>>) => r.body as Array<FormHistoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormHistoryByCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryByCompanyGet$Json$Response(params?: {
    idCompany?: number;
  }): Observable<StrictHttpResponse<Array<FormHistoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormHistoryByCompanyGetPath, 'get');
    if (params) {
      rb.query('idCompany', params.idCompany, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FormHistoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormHistoryByCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormHistoryByCompanyGet$Json(params?: {
    idCompany?: number;
  }): Observable<Array<FormHistoryDto>> {

    return this.apiFormHistoryByCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormHistoryDto>>) => r.body as Array<FormHistoryDto>)
    );
  }

  /**
   * Path part for operation apiFormPpInfosByCompanyGet
   */
  static readonly ApiFormPpInfosByCompanyGetPath = '/api/Form/PPInfosByCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormPpInfosByCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormPpInfosByCompanyGet$Plain$Response(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<StrictHttpResponse<Array<FormInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormPpInfosByCompanyGetPath, 'get');
    if (params) {
      rb.query('idCompany', params.idCompany, {});
      rb.query('maxItemCount', params.maxItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FormInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormPpInfosByCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormPpInfosByCompanyGet$Plain(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<Array<FormInfoDto>> {

    return this.apiFormPpInfosByCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormInfoDto>>) => r.body as Array<FormInfoDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormPpInfosByCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormPpInfosByCompanyGet$Json$Response(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<StrictHttpResponse<Array<FormInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormPpInfosByCompanyGetPath, 'get');
    if (params) {
      rb.query('idCompany', params.idCompany, {});
      rb.query('maxItemCount', params.maxItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FormInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormPpInfosByCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormPpInfosByCompanyGet$Json(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<Array<FormInfoDto>> {

    return this.apiFormPpInfosByCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormInfoDto>>) => r.body as Array<FormInfoDto>)
    );
  }

  /**
   * Path part for operation apiFormOtherPermitsInfosByCompanyGet
   */
  static readonly ApiFormOtherPermitsInfosByCompanyGetPath = '/api/Form/OtherPermitsInfosByCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormOtherPermitsInfosByCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormOtherPermitsInfosByCompanyGet$Plain$Response(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<StrictHttpResponse<Array<FormInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormOtherPermitsInfosByCompanyGetPath, 'get');
    if (params) {
      rb.query('idCompany', params.idCompany, {});
      rb.query('maxItemCount', params.maxItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FormInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormOtherPermitsInfosByCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormOtherPermitsInfosByCompanyGet$Plain(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<Array<FormInfoDto>> {

    return this.apiFormOtherPermitsInfosByCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormInfoDto>>) => r.body as Array<FormInfoDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormOtherPermitsInfosByCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormOtherPermitsInfosByCompanyGet$Json$Response(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<StrictHttpResponse<Array<FormInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormOtherPermitsInfosByCompanyGetPath, 'get');
    if (params) {
      rb.query('idCompany', params.idCompany, {});
      rb.query('maxItemCount', params.maxItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FormInfoDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormOtherPermitsInfosByCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormOtherPermitsInfosByCompanyGet$Json(params?: {
    idCompany?: number;
    maxItemCount?: number;
  }): Observable<Array<FormInfoDto>> {

    return this.apiFormOtherPermitsInfosByCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FormInfoDto>>) => r.body as Array<FormInfoDto>)
    );
  }

  /**
   * Path part for operation apiFormIdMesurePreventionGet
   */
  static readonly ApiFormIdMesurePreventionGetPath = '/api/Form/{id}/MesurePrevention';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormIdMesurePreventionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormIdMesurePreventionGet$Plain$Response(params: {
    id: number;
    typeForm?: TypeForm;
  }): Observable<StrictHttpResponse<Array<SituationTravailWithDangersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormIdMesurePreventionGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailWithDangersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormIdMesurePreventionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormIdMesurePreventionGet$Plain(params: {
    id: number;
    typeForm?: TypeForm;
  }): Observable<Array<SituationTravailWithDangersDto>> {

    return this.apiFormIdMesurePreventionGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailWithDangersDto>>) => r.body as Array<SituationTravailWithDangersDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormIdMesurePreventionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormIdMesurePreventionGet$Json$Response(params: {
    id: number;
    typeForm?: TypeForm;
  }): Observable<StrictHttpResponse<Array<SituationTravailWithDangersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormIdMesurePreventionGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailWithDangersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormIdMesurePreventionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormIdMesurePreventionGet$Json(params: {
    id: number;
    typeForm?: TypeForm;
  }): Observable<Array<SituationTravailWithDangersDto>> {

    return this.apiFormIdMesurePreventionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailWithDangersDto>>) => r.body as Array<SituationTravailWithDangersDto>)
    );
  }

  /**
   * Path part for operation apiFormDuplicatePost
   */
  static readonly ApiFormDuplicatePostPath = '/api/Form/Duplicate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormDuplicatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormDuplicatePost$Plain$Response(params?: {
    body?: FormDuplicationDto
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormDuplicatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormDuplicatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormDuplicatePost$Plain(params?: {
    body?: FormDuplicationDto
  }): Observable<number> {

    return this.apiFormDuplicatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormDuplicatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormDuplicatePost$Json$Response(params?: {
    body?: FormDuplicationDto
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormDuplicatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormDuplicatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormDuplicatePost$Json(params?: {
    body?: FormDuplicationDto
  }): Observable<number> {

    return this.apiFormDuplicatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation apiFormNewVersionPost
   */
  static readonly ApiFormNewVersionPostPath = '/api/Form/NewVersion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormNewVersionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormNewVersionPost$Plain$Response(params?: {
    body?: number
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormNewVersionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormNewVersionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormNewVersionPost$Plain(params?: {
    body?: number
  }): Observable<number> {

    return this.apiFormNewVersionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormNewVersionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormNewVersionPost$Json$Response(params?: {
    body?: number
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormNewVersionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormNewVersionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormNewVersionPost$Json(params?: {
    body?: number
  }): Observable<number> {

    return this.apiFormNewVersionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
