<div class="headerDocument">
  <div class="headerDocumentColonnes">
    <div class="headerDocumentColonneInfos">
      <label class="labelClair"></label>
    </div>
    <div class="headerDocumentColonneDateIntervention">
      <label class="labelClair">Date d'intervention</label>
    </div>
    <div class="headerDocumentColonneDateModification">
      <label class="labelClair">Modifié le</label>
    </div>
  </div>
</div>
