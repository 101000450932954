import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/Pages/local-storage.service';
import { Cst } from 'src/app/shared/utils/cst';

export enum EnumNavigation {
  NewPlanPrevention,
  NewPermisGeneral,
  NewPermisFeu,
  NewPermisHauteur,
  NewPermisElec,
  NewPermisConfine,
  NewEntreprise,
  NewSalarie
}

@Component({
  selector: 'app-new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.css']
})
export class NewDocumentComponent implements OnInit {

  EnumNavigation = EnumNavigation;
  // permisSpeToggled: boolean = false;

  constructor(private router: Router,
              private localStorageService: LocalStorageService) { }



  ngOnInit(): void {
    this.localStorageService.removeItem('permis');
    this.localStorageService.removeItem('permisElec');
    this.localStorageService.removeItem('permisConfine');
    this.localStorageService.removeItem('permisHauteur');
    this.localStorageService.removeItem('permisFeu');
    this.localStorageService.removeItem('mesures');
    this.localStorageService.removeItem('mesureslnk');
    this.localStorageService.removeItem('lnkPJDocument');
    this.localStorageService.removeItem('Document');
    this.localStorageService.removeItem('planPrevention');
    this.localStorageService.removeItem('permisJournalier');

  }

  // onPermisSpeToggled() {
  //   this.permisSpeToggled = !this.permisSpeToggled;
  // }

  openPage(value: EnumNavigation){
    let route: string;
    switch(value){
      case EnumNavigation.NewPlanPrevention:
        route = Cst.Routes.AddPlanDePrevention;
        break;
      case EnumNavigation.NewPermisGeneral:
        route = Cst.Routes.AddPermis;
        break;
      case EnumNavigation.NewPermisFeu:
        route = Cst.Routes.AddPermisFeu;
        break;
      case EnumNavigation.NewPermisHauteur:
        route = Cst.Routes.AddPermisHauteur;
        break;
      case EnumNavigation.NewPermisElec:
        route = Cst.Routes.AddPermisElec;
        break;
      case EnumNavigation.NewPermisConfine:
        route = Cst.Routes.AddPermisConfine;
        break;
      case EnumNavigation.NewEntreprise:
        route = Cst.Routes.AddEntreprise;
        break;
      case EnumNavigation.NewSalarie:
        route = Cst.Routes.AddSalarie;
        break;
    }
    this.router.navigateByUrl(route,);
  }

}
