/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EntityDto } from '../models/entity-dto';

@Injectable({
  providedIn: 'root',
})
export class EntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEntityGetCssctContactEmailsGet
   */
  static readonly ApiEntityGetCssctContactEmailsGetPath = '/api/Entity/GetCSSCTContactEmails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityGetCssctContactEmailsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGetCssctContactEmailsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityGetCssctContactEmailsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityGetCssctContactEmailsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGetCssctContactEmailsGet$Plain(params?: {
  }): Observable<Array<string>> {

    return this.apiEntityGetCssctContactEmailsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityGetCssctContactEmailsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGetCssctContactEmailsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityGetCssctContactEmailsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityGetCssctContactEmailsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGetCssctContactEmailsGet$Json(params?: {
  }): Observable<Array<string>> {

    return this.apiEntityGetCssctContactEmailsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation apiEntityGet
   */
  static readonly ApiEntityGetPath = '/api/Entity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<EntityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EntityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<EntityDto>> {

    return this.apiEntityGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EntityDto>>) => r.body as Array<EntityDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<EntityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EntityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<EntityDto>> {

    return this.apiEntityGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EntityDto>>) => r.body as Array<EntityDto>)
    );
  }

  /**
   * Path part for operation apiEntityPost
   */
  static readonly ApiEntityPostPath = '/api/Entity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityPost$Plain$Response(params?: {
    body?: EntityDto
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityPost$Plain(params?: {
    body?: EntityDto
  }): Observable<EntityDto> {

    return this.apiEntityPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityPost$Json$Response(params?: {
    body?: EntityDto
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityPost$Json(params?: {
    body?: EntityDto
  }): Observable<EntityDto> {

    return this.apiEntityPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * Path part for operation apiEntityIdGet
   */
  static readonly ApiEntityIdGetPath = '/api/Entity/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityIdGet$Plain(params: {
    id: number;
  }): Observable<EntityDto> {

    return this.apiEntityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityIdGet$Json(params: {
    id: number;
  }): Observable<EntityDto> {

    return this.apiEntityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * Path part for operation apiEntityIdPut
   */
  static readonly ApiEntityIdPutPath = '/api/Entity/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityIdPut$Plain$Response(params: {
    id: number;
    body?: EntityDto
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityIdPut$Plain(params: {
    id: number;
    body?: EntityDto
  }): Observable<EntityDto> {

    return this.apiEntityIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityIdPut$Json$Response(params: {
    id: number;
    body?: EntityDto
  }): Observable<StrictHttpResponse<EntityDto>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EntityDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEntityIdPut$Json(params: {
    id: number;
    body?: EntityDto
  }): Observable<EntityDto> {

    return this.apiEntityIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EntityDto>) => r.body as EntityDto)
    );
  }

  /**
   * Path part for operation apiEntityIdDelete
   */
  static readonly ApiEntityIdDeletePath = '/api/Entity/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEntityIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EntityService.ApiEntityIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEntityIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEntityIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiEntityIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
