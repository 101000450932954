<div id="sideBar">
  <!-- <div class="spacer"></div> -->
  <div class="topPicto"><a routerLink= "/lastupdates"><img class="imgSideBar" src="../../../assets/icones/Picto_historique.svg"></a></div>
  <div><a routerLink= "/newDocument"><img class="imgSideBar" src="../../../assets/icones/picto_ajouter.svg"></a></div>
  <div class="botPicto"><a routerLink= "/params"><img class="imgSideBar" src="../../../assets/icones/Picto_admin.svg"></a></div>
  <!-- <div><a routerLink= "/preventionPlan"><img class="imgSideBar" src="../assets/icones/PP.svg"></a></div>
  <div><a routerLink= "/plan"><img class="imgSideBar" src="../assets/icones/PJ.svg"></a></div>
  <div><a routerLink= "/planFire"><img class="imgSideBar" src="../assets/icones/PF.svg"></a></div>
  <div><a routerLink= "/planHeight"><img class="imgSideBar" src="../assets/icones/PH.svg"></a></div>
  <div><a routerLink= "/planLockdown"><img class="imgSideBar" src="../assets/icones/PEC.svg"></a></div>
  <div><a routerLink= "/planConsignation"><img class="imgSideBar" src="../assets/icones/PE.svg"></a></div> -->

</div>
