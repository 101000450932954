/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SpFeuDto } from '../models/sp-feu-dto';

@Injectable({
  providedIn: 'root',
})
export class PermisSpecifiqueFeuService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPermisSpecifiqueFeuGet
   */
  static readonly ApiPermisSpecifiqueFeuGetPath = '/api/PermisSpecifiqueFeu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpFeuDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpFeuDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpFeuDto>> {

    return this.apiPermisSpecifiqueFeuGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpFeuDto>>) => r.body as Array<SpFeuDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpFeuDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpFeuDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpFeuDto>> {

    return this.apiPermisSpecifiqueFeuGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpFeuDto>>) => r.body as Array<SpFeuDto>)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueFeuPost
   */
  static readonly ApiPermisSpecifiqueFeuPostPath = '/api/PermisSpecifiqueFeu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuPost$Plain$Response(params?: {
    body?: SpFeuDto
  }): Observable<StrictHttpResponse<SpFeuDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpFeuDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuPost$Plain(params?: {
    body?: SpFeuDto
  }): Observable<SpFeuDto> {

    return this.apiPermisSpecifiqueFeuPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpFeuDto>) => r.body as SpFeuDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuPost$Json$Response(params?: {
    body?: SpFeuDto
  }): Observable<StrictHttpResponse<SpFeuDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpFeuDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuPost$Json(params?: {
    body?: SpFeuDto
  }): Observable<SpFeuDto> {

    return this.apiPermisSpecifiqueFeuPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpFeuDto>) => r.body as SpFeuDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueFeuIdReportGet
   */
  static readonly ApiPermisSpecifiqueFeuIdReportGetPath = '/api/PermisSpecifiqueFeu/{id}/Report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuIdReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdReportGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuIdReportGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuIdReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdReportGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiPermisSpecifiqueFeuIdReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueFeuIdGet
   */
  static readonly ApiPermisSpecifiqueFeuIdGetPath = '/api/PermisSpecifiqueFeu/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpFeuDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpFeuDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdGet$Plain(params: {
    id: number;
  }): Observable<SpFeuDto> {

    return this.apiPermisSpecifiqueFeuIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpFeuDto>) => r.body as SpFeuDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpFeuDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpFeuDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdGet$Json(params: {
    id: number;
  }): Observable<SpFeuDto> {

    return this.apiPermisSpecifiqueFeuIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpFeuDto>) => r.body as SpFeuDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueFeuIdPut
   */
  static readonly ApiPermisSpecifiqueFeuIdPutPath = '/api/PermisSpecifiqueFeu/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuIdPut$Plain$Response(params: {
    id: number;
    body?: SpFeuDto
  }): Observable<StrictHttpResponse<SpFeuDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpFeuDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuIdPut$Plain(params: {
    id: number;
    body?: SpFeuDto
  }): Observable<SpFeuDto> {

    return this.apiPermisSpecifiqueFeuIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpFeuDto>) => r.body as SpFeuDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuIdPut$Json$Response(params: {
    id: number;
    body?: SpFeuDto
  }): Observable<StrictHttpResponse<SpFeuDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpFeuDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueFeuIdPut$Json(params: {
    id: number;
    body?: SpFeuDto
  }): Observable<SpFeuDto> {

    return this.apiPermisSpecifiqueFeuIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpFeuDto>) => r.body as SpFeuDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueFeuIdDelete
   */
  static readonly ApiPermisSpecifiqueFeuIdDeletePath = '/api/PermisSpecifiqueFeu/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueFeuIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueFeuService.ApiPermisSpecifiqueFeuIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueFeuIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueFeuIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiPermisSpecifiqueFeuIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
