import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { LocalStorageService } from '../Pages/local-storage.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private authService: AuthService
  ) {

  }

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );



  injectToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.Token}`,
      },
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //Renew token is using the token for now, so we keep it under the intercept action
    if (/*request.url.toLowerCase().includes('refreshtoken') || */request.url.toLowerCase().includes('login')) {
      return next.handle(request);
    }

    const accessExpired = this.authService.isAccessTokenExpired();
    const refreshExpired = this.authService.NeedRefreshToken();

    if (accessExpired && refreshExpired) {
      return next.handle(request);
    }
    if (accessExpired && !refreshExpired) {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.authService.renewToken().pipe(
          switchMap((renewed) => {
            if(renewed)
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(this.authService.Token);
            return next.handle(this.injectToken(request));
          })
        );
      } else {
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request));
          })
        );
      }
    }

    if (!accessExpired) {
      return next
        .handle(this.injectToken(request));
    }
  }
}
