import { Injectable } from '@angular/core';
import {
  CompanyService,
  DonneurOrdreService,
  EmployeeService,
  DocumentPhysiqueService,
  InterventionAreaService,
  MesurePreventionService,
  DocumentJustificatifService,
  FormService,
} from 'src/app/shared/services';
import {
  CompanyDto,
  DocumentJustificatifDto,
  DocumentPhysiqueDto,
  DonneurOrdreDto,
  EmployeeDto,
  FormDuplicationDto,
  InterventionAreaDto,
  MesurePreventionDto,
  TypeForm,
} from 'src/app/shared/models';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { StrictHttpResponse } from 'src/app/shared/strict-http-response';
import { Cst } from 'src/app/shared/utils/cst';

@Injectable({
  providedIn: 'root',
})
export class PermisService {
  constructor(
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private donneurOrdreService: DonneurOrdreService,
    private interventionAreaService: InterventionAreaService,
    private documentService: DocumentPhysiqueService,
    private mesurePreventionService: MesurePreventionService,
    private documentJustificatifService: DocumentJustificatifService,
    private formService : FormService
  ) {}

  //#region Cache
  private Companies: CompanyDto[] = [];
  private DonneurOrdres: DonneurOrdreDto[] = [];
  private InterventionAreas: InterventionAreaDto[] = [];
  private Employees: EmployeeDto[] = [];
  private Documents: DocumentPhysiqueDto[] = [];
  private MesurePreventions: MesurePreventionDto[] = [];
  private DocumentsJustificatif: DocumentJustificatifDto[] = [];
  //#endregion

  //#region Companies
  /**
   * Récupère la liste des entreprises
   * @returns {Observable<CompanyDto[]>} Liste
   */
  public getCompanies(): Observable<CompanyDto[]> {
    if (this.Companies && this.Companies.length > 0) {
      return of(this.Companies);
    } else {
      return this.companyService
        .apiCompanyGet$Json()
        .pipe(tap({ next: (co) => (this.Companies = co) }));
    }
  }

  /**
   * Récupère une entreprise donnée
   * @param id id de l'entreprise
   * @returns {Observable<CompanyDto>} Entreprise
   */
  public getCompanyById(id: number): Observable<CompanyDto> {
    let company: CompanyDto;
    if (this.Companies) {
      company = this.Companies.find((c) => c.idCompany == id);
    }
    if (company) {
      return of(company);
    } else {
      return this.companyService
        .apiCompanyIdGet$Json({ id: id })
        .pipe(tap({ next: (c) => this.Companies.push(c) }));
    }
  }

  /**
   * Fonction à utiliser pour trier liste des entreprises sur le nom
   */
  public toSortCompaniesOnName(a: CompanyDto, b: CompanyDto): number {
    if (a.nom.toLowerCase() < b.nom.toLowerCase()) {
      return -1;
    } else if (a.nom.toLowerCase() > b.nom.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }
  //#endregion

  //#region DonneurOrdre
  /**
   * Récupère la liste des donneurs d'ordre
   * @returns {Observable<DonneurOrdreDto[]>} Liste
   */
  public getDonneurOrdres(): Observable<DonneurOrdreDto[]> {
    if (this.DonneurOrdres && this.DonneurOrdres.length > 0) {
      return of(this.DonneurOrdres);
    } else {
      return this.donneurOrdreService
        .apiDonneurOrdreGet$Json()
        .pipe(tap({ next: (DO) => (this.DonneurOrdres = DO) }));
    }
  }

  /**
   * Récupère un donneur d'ordre spécifié
   * @param id Id du donneur d'ordre
   * @returns {Observable<DonneurOrdreDto>} donneur d'ordre
   */
  public getDonneurOrdreById(id: number): Observable<DonneurOrdreDto> {
    let donneurOrdre: DonneurOrdreDto;
    if (this.DonneurOrdres) {
      donneurOrdre = this.DonneurOrdres.find((d) => d.idDonneurOrdre == id);
    }

    if (donneurOrdre) {
      return of(donneurOrdre);
    } else {
      return this.donneurOrdreService
        .apiDonneurOrdreIdGet$Json({ id: id })
        .pipe(tap({ next: (d) => this.DonneurOrdres.push(d) }));
    }
  }

  /**
   * Fonction à utiliser pour trier liste des donneurs d'ordre sur le nom et le prénom
   */
  public toSortDonneurOrdreOnLastnameFirstName(
    a: DonneurOrdreDto,
    b: DonneurOrdreDto
  ): number {
    if (
      (a.lastName + a.firstName).toLowerCase() <
      (b.lastName + b.firstName).toLowerCase()
    ) {
      return -1;
    } else if (
      (a.lastName + a.firstName).toLowerCase() >
      (b.lastName + b.firstName).toLowerCase()
    ) {
      return 1;
    } else {
      return 0;
    }
  }
  //#endregion

  //#region InterventionArea
  /**
   * Récupère la liste des zones d'intervention
   * @returns {Observable<InterventionAreaDto[]>} Liste
   */
  public getInterventionAreas(): Observable<InterventionAreaDto[]> {
    if (this.InterventionAreas && this.InterventionAreas.length > 0) {
      return of(this.InterventionAreas);
    } else {
      return this.interventionAreaService
        .apiInterventionAreaForCurrentUserGet$Json()
        .pipe(tap({ next: (ia) => (this.InterventionAreas = ia) }));
    }
  }
  //#endregion

  //#region Employee
  /**
   * Récupère la liste des employés
   * @returns {Observable<EmployeeDto[]>} Liste
   */
  public getEmployees(): Observable<EmployeeDto[]> {
    if (this.Employees && this.Employees.length > 0) {
      return of(this.Employees);
    } else {
      return this.employeeService
        .apiEmployeeGet$Json()
        .pipe(tap({ next: (emp) => (this.Employees = emp) }));
    }
  }

  /**
   * Récupère un employé spécifié
   * @param id identifiant de l'employé
   * @returns {Observable<EmployeeDto>} Employé
   */
  public getEmployeeById(id: number): Observable<EmployeeDto> {
    let employee: EmployeeDto;
    if (this.Employees) {
      employee = this.Employees.find((e) => e.idEmployee == id);
    }

    if (employee) {
      return of(employee);
    } else {
      return this.employeeService
        .apiEmployeeIdGet$Json({ id: id })
        .pipe(tap({ next: (e) => this.Employees.push(e) }));
    }
  }
  //#endregion

  //#region Documents
  /**
   * Récupère la liste des documents
   * @returns {Observable<DocumentPhysiqueDto[]>} Liste
   */
  public getDocuments(): Observable<DocumentPhysiqueDto[]> {
    if (this.Documents && this.Documents.length > 0) {
      return of(this.Documents);
    } else {
      return this.documentService
        .apiDocumentPhysiqueGet$Json()
        .pipe(tap({ next: (doc) => (this.Documents = doc) }));
    }
  }

  /**
   * Récupère un document spécifié
   * @param id identifiant du document
   * @returns {Observable<DocumentPhysiqueDto>} Document
   */
  public getDocumentById(id: number): Observable<DocumentPhysiqueDto> {
    let doc: DocumentPhysiqueDto;
    if (this.Documents) {
      doc = this.Documents.find((d) => d.idDocumentPhysique == id);
    }

    if (doc) {
      return of(doc);
    } else {
      return this.documentService
        .apiDocumentPhysiqueIdGet$Json({ id: id })
        .pipe(tap({ next: (ret) => this.Documents.push(ret) }));
    }
  }

  /**
   * Envoie un nouveau document
   * @param document Document
   * @returns {Observable<DocumentPhysiqueDto>} Document créé
   */
  public postDocument(
    document: DocumentPhysiqueDto
  ): Observable<DocumentPhysiqueDto> {
    return this.documentService.apiDocumentPhysiquePost$Json({
      body: document,
    });
  }

  /**
   * Supprime un document physique et ses données
   * @param id DocumentPhysique Id
   * @returns Observable<void>
   */
  public deleteDocument(id: number): Observable<void> {
    return this.documentService.apiDocumentPhysiqueIdDelete({ id: id }).pipe(
      tap({
        next: () => {
          let index = this.Documents.findIndex(
            (d) => d.idDocumentPhysique == id
          );
          if (id) {
            this.Documents.splice(index, 1);
          }
        },
      })
    );
  }

  /**
   * Envoie le fichier correspondant à un document
   * @param id identifiant du document
   * @param file fichier
   * @returns
   */
  public postDocumentData(id: number, file: File): Observable<void> {
    return this.documentService.apiDocumentPhysiqueIdDataPost({
      id: id,
      body: { file: file },
    });
  }

  /**
   * Récupère le fichier correspondant à un document
   * @param id identifiant du document
   * @returns {Observable<StrictHttpResponse<string>>} réponse contenant le flux du fichier
   */
  public getDocumentDataById(
    id: number
  ): Observable<StrictHttpResponse<string>> {
    return this.documentService.apiDocumentPhysiqueIdDataGet$Response({
      id: id,
    });
  }
  //#endregion

  //#region MesurePrevention
  /**
   * Récupère la liste des mesures de prévention
   * @returns {Observable<MesurePreventionDto[]>} Liste
   */
  public getMesurePreventions(): Observable<MesurePreventionDto[]> {
    if (this.MesurePreventions && this.MesurePreventions.length > 0) {
      return of(this.MesurePreventions);
    } else {
      return this.mesurePreventionService
        .apiMesurePreventionGet$Json()
        .pipe(tap({ next: (mp) => (this.MesurePreventions = mp) }));
    }
  }
  //#endregion

  //#region DocumentsJutificatifs
  public getDocumentsJustificatifs(): Observable<DocumentJustificatifDto[]> {
    if (this.DocumentsJustificatif && this.DocumentsJustificatif.length > 0) {
      return of(this.DocumentsJustificatif);
    } else {
      return this.documentJustificatifService
        .apiDocumentJustificatifGet$Json()
        .pipe(tap({ next: (docs) => (this.DocumentsJustificatif = docs) }));
    }
  }
  //#endregion

  //#region duplicatePermis
  public duplicatePermis(idSource:number, permisSource : TypeForm, permisDestination : TypeForm): Observable<string> {
    // Construction des infos à envoyer au back
    let bodyDuplication : FormDuplicationDto = {
      idFormSource: idSource,
      typeFormDestination: permisDestination,
      typeFormSource: permisSource
    }

    // Récupère id du nouveau permis dupliqué
    let obs: Observable<string>;
    obs = this.formService.apiFormDuplicatePost$Json(
      {
        body: bodyDuplication,
      }
    )
    .pipe(
      switchMap((data) => {
        if (data) {
          // Récupère nom du permis destination
          let newUrl;
          switch(permisDestination) {
            case(TypeForm.Pp) :
              newUrl = Cst.Routes.EditPlanDePrevention;
              break;
            case(TypeForm.Pj) :
              newUrl = Cst.Routes.EditPermis;
              break;
            case(TypeForm.Pc) :
              newUrl = Cst.Routes.EditPermisConfine;
              break;
            case(TypeForm.Pe) :
              newUrl = Cst.Routes.EditPermisElec;
              break;
            case(TypeForm.Ph) :
              newUrl = Cst.Routes.EditPermisHauteur;
              break;
            case(TypeForm.Pf) :
              newUrl =  Cst.Routes.EditPermisFeu;
              break;
            default :
              newUrl = '';
              break;
          }

          return of(newUrl + data);
        }
      })
    );
    return obs;
  }

  public createNewVersionPP(idSource:number): Observable<string> {
    // Récupère id du nouveau permis dupliqué
    let obs: Observable<string>;
    obs = this.formService.apiFormNewVersionPost$Json(
      {
        body: idSource
      }
    )
    .pipe(
      switchMap((data) => {
        if (data) {
          return of(Cst.Routes.EditPlanDePrevention + data);
        }
      })
    );
    return obs;
  }
  //#endregion
}
