/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AlertsDto } from '../models/alerts-dto';

@Injectable({
  providedIn: 'root',
})
export class AlertService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAlertGet
   */
  static readonly ApiAlertGetPath = '/api/Alert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAlertGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAlertGet$Plain$Response(params?: {
    onlyActives?: boolean;
    noCache?: boolean;
  }): Observable<StrictHttpResponse<AlertsDto>> {

    const rb = new RequestBuilder(this.rootUrl, AlertService.ApiAlertGetPath, 'get');
    if (params) {
      rb.query('onlyActives', params.onlyActives, {});
      rb.query('noCache', params.noCache, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAlertGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAlertGet$Plain(params?: {
    onlyActives?: boolean;
    noCache?: boolean;
  }): Observable<AlertsDto> {

    return this.apiAlertGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AlertsDto>) => r.body as AlertsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAlertGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAlertGet$Json$Response(params?: {
    onlyActives?: boolean;
    noCache?: boolean;
  }): Observable<StrictHttpResponse<AlertsDto>> {

    const rb = new RequestBuilder(this.rootUrl, AlertService.ApiAlertGetPath, 'get');
    if (params) {
      rb.query('onlyActives', params.onlyActives, {});
      rb.query('noCache', params.noCache, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAlertGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAlertGet$Json(params?: {
    onlyActives?: boolean;
    noCache?: boolean;
  }): Observable<AlertsDto> {

    return this.apiAlertGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AlertsDto>) => r.body as AlertsDto)
    );
  }

  /**
   * Path part for operation apiAlertAcknowledgePost
   */
  static readonly ApiAlertAcknowledgePostPath = '/api/Alert/Acknowledge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAlertAcknowledgePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAlertAcknowledgePost$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AlertService.ApiAlertAcknowledgePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAlertAcknowledgePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAlertAcknowledgePost(params?: {
    body?: Array<string>
  }): Observable<void> {

    return this.apiAlertAcknowledgePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
