<div class="pageLastUpdates">
  <app-filtre-documents [(stateVisu)]="stateVisu" [defaultFilterTypeDocument]="filterDocuments.typeForm" (applyFilterCompany)="onApplyFilterCompany($event)"
  (applyFilterDocument)="onApplyFilterDocument($event)" (stateVisuChange)="onToggleVisuChange($event)" ></app-filtre-documents>
  <div class="listeResultats">
      <div *ngIf="!stateVisu">
        <div *ngIf="!isDocumentsSearchLaunched" class="divForTextInResults">
          Veuillez lancer une recherche
        </div>
        <div *ngIf="isDocumentsSearchLaunched && documents.length == 0" class="divForTextInResults">
          Aucun résultat
        </div>
        <div *ngIf="isDocumentsSearchLaunched && documents.length > 0">
          <app-header-visu-document></app-header-visu-document>
          <div class="ligneListeDocuments" *ngFor="let doc of documents">
            <app-visu-document  [document]="doc" (editAction)="editDocument(doc)" (archiveAction)="archiveDocument(doc)"></app-visu-document>
          </div>
      </div>
      </div>
      <div *ngIf="stateVisu">
        <div *ngIf="!isCompaniesSearchLaunched" class="divForTextInResults">
          Veuillez lancer une recherche
        </div>
        <div *ngIf="isCompaniesSearchLaunched && companies.length == 0" class="divForTextInResults">
          Aucun résultat
        </div>
        <div *ngIf="isCompaniesSearchLaunched && companies.length > 0">
          <app-header-visu-societe></app-header-visu-societe>
          <div class="ligneListeSocietes" *ngFor="let comp of companies">
            <app-visu-societe *ngIf="stateVisu" [societe]="comp" (editAction)="editSociete(comp)" (detailsAction)="detailsSociete(comp)" (archiveAction)="archiveDocument(comp)"></app-visu-societe>
          </div>
        </div>

      </div>
  </div>
</div>
