import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {Cst } from 'src/app/shared/utils/cst';

@Component({
  selector: 'app-visu-societe',
  templateUrl: './visu-societe.component.html',
  styleUrls: ['./visu-societe.component.css']
})
export class VisuSocieteComponent implements OnInit {
  @Input() societe: any;
  @Output() editAction = new EventEmitter();
  @Output() archiveAction = new EventEmitter();
  @Output() detailsAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  editItem() {
    this.editAction.emit(this.societe);
  }

  detailsItem(){

    this.detailsAction.emit(this.societe);
  }

  archiveItem() {
    this.archiveAction.emit(this.societe);
  }

  getDocumentImage(type: string, statut: number){
    const imageName: string = type + statut;
    return imageName;
  }
}
