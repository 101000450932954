import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-visu-document',
  templateUrl: './visu-document.component.html',
  styleUrls: ['./visu-document.component.css'],
})
export class VisuDocumentComponent {
  @Input() document: any;
  @Output() editAction = new EventEmitter();
  @Output() archiveAction = new EventEmitter();

  editItem() {
    this.editAction.emit(this.document);
  }
  archiveItem() {
    this.archiveAction.emit(this.document);
  }

  getDocumentImage(type: string, statut: number) {
    const imageName: string = type + statut;
    return imageName;
  }

  typeDocumentEnClair(psCodeType: string) {
    let sType: string = '';

    switch (psCodeType) {
      case 'PP':
        sType = 'Plan de prévention';
        break;
      case 'PJ':
        sType = 'Permis journalier';
        break;
      case 'PF':
        sType = "Permis 'Feu'";
        break;
      case 'PH':
        sType = "Permis 'Travail en hauteur'";
        break;
      case 'PC':
        sType = "Permis 'Espace confiné'";
        break;
      case 'PE':
        sType = "Permis 'Consignation'";
        break;
      default:
        sType = 'Type inconnu';
        break;
    }

    return sType;
  }
}
