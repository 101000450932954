import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../class/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  public currentUser: Observable<User>;

  constructor(
    private router: Router) { }

  login(username: string, password: string) {
    if (username == "admin" && password == "admin"){
      this.handleAuth({id:0, username:"admn", firstName: "Test", lastName:"Admin", token:"token"})
      return true;
    }
    else {
      return false;
    }
    const parameters = { username: username, password: password };

    // return this.apiHelper.requestApi({ action: Cst.WebServices.method.login, method: 'post', datas: parameters });


  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/login']);
  }


  // getAll() {
  //   return this.apiHelper.requestApi({ action: Cst.WebServices.method.getUsers, method: 'get' });
  // }


  // getById(id = '') {
  //   const parameters = {
  //     UserId: id
  //   };

  //   return this.apiHelper.requestApi({ action: Cst.WebServices.method.getUser, method: 'get', datas: parameters });
  // }

  public handleAuth({ id, username, firstName, lastName, token }
    : { id: number, username: string, firstName: string, lastName: string, token: string }) {
    const user = new User(id, username, firstName, lastName, token);
    this.user.next(user);
  }


  // getById(data: any): Observable<any>{
  //let params1= new HttpParams().set("id", "1");
  // return this.http.get("http://localhost:60403/Users/1",{params: data});
  // }
}
