/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DangerDto } from '../models/danger-dto';
import { RisqueDto } from '../models/risque-dto';
import { TypeForm } from '../models/type-form';

@Injectable({
  providedIn: 'root',
})
export class DangerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiDangerGet
   */
  static readonly ApiDangerGetPath = '/api/Danger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DangerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DangerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DangerDto>> {

    return this.apiDangerGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DangerDto>>) => r.body as Array<DangerDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DangerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DangerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DangerDto>> {

    return this.apiDangerGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DangerDto>>) => r.body as Array<DangerDto>)
    );
  }

  /**
   * Path part for operation apiDangerPost
   */
  static readonly ApiDangerPostPath = '/api/Danger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerPost$Plain$Response(params?: {
    body?: DangerDto
  }): Observable<StrictHttpResponse<DangerDto>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DangerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerPost$Plain(params?: {
    body?: DangerDto
  }): Observable<DangerDto> {

    return this.apiDangerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DangerDto>) => r.body as DangerDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerPost$Json$Response(params?: {
    body?: DangerDto
  }): Observable<StrictHttpResponse<DangerDto>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DangerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerPost$Json(params?: {
    body?: DangerDto
  }): Observable<DangerDto> {

    return this.apiDangerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DangerDto>) => r.body as DangerDto)
    );
  }

  /**
   * Path part for operation apiDangerIdGet
   */
  static readonly ApiDangerIdGetPath = '/api/Danger/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DangerDto>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DangerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdGet$Plain(params: {
    id: number;
  }): Observable<DangerDto> {

    return this.apiDangerIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DangerDto>) => r.body as DangerDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DangerDto>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DangerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdGet$Json(params: {
    id: number;
  }): Observable<DangerDto> {

    return this.apiDangerIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DangerDto>) => r.body as DangerDto)
    );
  }

  /**
   * Path part for operation apiDangerIdPut
   */
  static readonly ApiDangerIdPutPath = '/api/Danger/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerIdPut$Plain$Response(params: {
    id: number;
    body?: DangerDto
  }): Observable<StrictHttpResponse<DangerDto>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DangerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerIdPut$Plain(params: {
    id: number;
    body?: DangerDto
  }): Observable<DangerDto> {

    return this.apiDangerIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DangerDto>) => r.body as DangerDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerIdPut$Json$Response(params: {
    id: number;
    body?: DangerDto
  }): Observable<StrictHttpResponse<DangerDto>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DangerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDangerIdPut$Json(params: {
    id: number;
    body?: DangerDto
  }): Observable<DangerDto> {

    return this.apiDangerIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DangerDto>) => r.body as DangerDto)
    );
  }

  /**
   * Path part for operation apiDangerIdDelete
   */
  static readonly ApiDangerIdDeletePath = '/api/Danger/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiDangerIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiDangerIdRisqueGet
   */
  static readonly ApiDangerIdRisqueGetPath = '/api/Danger/{id}/Risque';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdRisqueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<RisqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdRisqueGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RisqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdRisqueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGet$Plain(params: {
    id: number;
  }): Observable<Array<RisqueDto>> {

    return this.apiDangerIdRisqueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RisqueDto>>) => r.body as Array<RisqueDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdRisqueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<RisqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdRisqueGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RisqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdRisqueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGet$Json(params: {
    id: number;
  }): Observable<Array<RisqueDto>> {

    return this.apiDangerIdRisqueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RisqueDto>>) => r.body as Array<RisqueDto>)
    );
  }

  /**
   * Path part for operation apiDangerIdRisqueGetAllByFormGet
   */
  static readonly ApiDangerIdRisqueGetAllByFormGetPath = '/api/Danger/{id}/Risque/GetAllByForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdRisqueGetAllByFormGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGetAllByFormGet$Plain$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<RisqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdRisqueGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RisqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdRisqueGetAllByFormGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGetAllByFormGet$Plain(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<RisqueDto>> {

    return this.apiDangerIdRisqueGetAllByFormGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RisqueDto>>) => r.body as Array<RisqueDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDangerIdRisqueGetAllByFormGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGetAllByFormGet$Json$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<RisqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DangerService.ApiDangerIdRisqueGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RisqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDangerIdRisqueGetAllByFormGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDangerIdRisqueGetAllByFormGet$Json(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<RisqueDto>> {

    return this.apiDangerIdRisqueGetAllByFormGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RisqueDto>>) => r.body as Array<RisqueDto>)
    );
  }

}
