export class lastUpdateDocument {
    private _idForm: number;
    private _typeForm: string;
    private _intervention: string;
    private _companyNom: string;
    private _interventionDateDebut: Date;
    private _interventionDateFin: Date;
    private _modificationDate: Date;
    private _donneurOrdreName: string;
    private _interventionAreaName: string;

    constructor({idForm = -1, typeForm= '', intervention = "", companyNom = "", interventionDateDebut = null, interventionDateFin = null,
      modificationDate = null, donneurOrdreName = "", interventionAreaName = ""}){
      this._idForm = idForm;
      this._typeForm = typeForm;
      this._intervention = intervention;
      this._companyNom = companyNom;
      this._interventionDateDebut = interventionDateDebut;
      this._interventionDateFin = interventionDateFin;
      this._modificationDate = modificationDate;
      this._donneurOrdreName = donneurOrdreName;
      this._interventionAreaName = interventionAreaName;
    }

    get idForm(): number {
      return this._idForm;
    }

    set idForm(value: number) {
      this._idForm = value;
    }

    get typeForm(): string {
      return this._typeForm;
    }

    set typeForm(value: string) {
      this._typeForm = value;
    }

    get intervention(): string {
      return this._intervention;
    }

    set intervention(value: string) {
      this._intervention = value;
    }

    get companyNom(): string {
      return this._companyNom;
    }

    set companyNom(value: string) {
      this._companyNom = value;
    }

    get interventionDateDebut(): Date {
      return this._interventionDateDebut;
    }

    set interventionDateDebut(value: Date) {
      this._interventionDateDebut = value;
    }

    get interventionDateFin(): Date {
      return this._interventionDateFin;
    }

    set interventionDateFin(value: Date) {
      this._interventionDateFin = value;
    }

    get modificationDate(): Date {
      return this._modificationDate;
    }

    set modificationDate(value: Date) {
      this._modificationDate = value;
    }

    get donneurOrdreName(): string {
      return this._donneurOrdreName;
    }

    set donneurOrdreName(value: string) {
      this._donneurOrdreName = value;
    }

    get interventionAreaName(): string {
      return this._interventionAreaName;
    }

    set interventionAreaName(value: string) {
      this._interventionAreaName = value;
    }

  }
