<h2 mat-dialog-title>{{data.titre}}</h2>
<mat-dialog-content [innerHTML]="data.message"></mat-dialog-content>
<mat-dialog-actions *ngIf="data.choixReponse">
  <button mat-button mat-dialog-close color="primary">Annuler</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="true" color="warn">Confirmer</button>
</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="!data.choixReponse" >
  <button mat-button mat-dialog-close color="primary">Retour</button>
</mat-dialog-actions>
