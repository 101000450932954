import { NativeDateAdapter } from "@angular/material/core";
import { DateAdapter, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import * as _moment from 'moment';
import 'moment/locale/fr';




export const MOMENT_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY'
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM Y',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM Y'
    }
};