/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentPhysiqueDto } from '../models/document-physique-dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentPhysiqueService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiDocumentPhysiqueIdDataGet
   */
  static readonly ApiDocumentPhysiqueIdDataGetPath = '/api/DocumentPhysique/{id}/Data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueIdDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdDataGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueIdDataGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueIdDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdDataGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiDocumentPhysiqueIdDataGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiDocumentPhysiqueIdDataPost
   */
  static readonly ApiDocumentPhysiqueIdDataPostPath = '/api/DocumentPhysique/{id}/Data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueIdDataPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiDocumentPhysiqueIdDataPost$Response(params: {
    id: number;
    body?: {

/**
 * Upload File
 */
'file': File;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueIdDataPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueIdDataPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiDocumentPhysiqueIdDataPost(params: {
    id: number;
    body?: {

/**
 * Upload File
 */
'file': File;
}
  }): Observable<void> {

    return this.apiDocumentPhysiqueIdDataPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiDocumentPhysiqueGet
   */
  static readonly ApiDocumentPhysiqueGetPath = '/api/DocumentPhysique';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DocumentPhysiqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentPhysiqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DocumentPhysiqueDto>> {

    return this.apiDocumentPhysiqueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentPhysiqueDto>>) => r.body as Array<DocumentPhysiqueDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DocumentPhysiqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentPhysiqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DocumentPhysiqueDto>> {

    return this.apiDocumentPhysiqueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentPhysiqueDto>>) => r.body as Array<DocumentPhysiqueDto>)
    );
  }

  /**
   * Path part for operation apiDocumentPhysiquePost
   */
  static readonly ApiDocumentPhysiquePostPath = '/api/DocumentPhysique';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiquePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiquePost$Plain$Response(params?: {
    body?: DocumentPhysiqueDto
  }): Observable<StrictHttpResponse<DocumentPhysiqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiquePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPhysiqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiquePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiquePost$Plain(params?: {
    body?: DocumentPhysiqueDto
  }): Observable<DocumentPhysiqueDto> {

    return this.apiDocumentPhysiquePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPhysiqueDto>) => r.body as DocumentPhysiqueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiquePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiquePost$Json$Response(params?: {
    body?: DocumentPhysiqueDto
  }): Observable<StrictHttpResponse<DocumentPhysiqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiquePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPhysiqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiquePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiquePost$Json(params?: {
    body?: DocumentPhysiqueDto
  }): Observable<DocumentPhysiqueDto> {

    return this.apiDocumentPhysiquePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPhysiqueDto>) => r.body as DocumentPhysiqueDto)
    );
  }

  /**
   * Path part for operation apiDocumentPhysiqueIdGet
   */
  static readonly ApiDocumentPhysiqueIdGetPath = '/api/DocumentPhysique/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DocumentPhysiqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPhysiqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdGet$Plain(params: {
    id: number;
  }): Observable<DocumentPhysiqueDto> {

    return this.apiDocumentPhysiqueIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPhysiqueDto>) => r.body as DocumentPhysiqueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DocumentPhysiqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPhysiqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdGet$Json(params: {
    id: number;
  }): Observable<DocumentPhysiqueDto> {

    return this.apiDocumentPhysiqueIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPhysiqueDto>) => r.body as DocumentPhysiqueDto)
    );
  }

  /**
   * Path part for operation apiDocumentPhysiqueIdPut
   */
  static readonly ApiDocumentPhysiqueIdPutPath = '/api/DocumentPhysique/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiqueIdPut$Plain$Response(params: {
    id: number;
    body?: DocumentPhysiqueDto
  }): Observable<StrictHttpResponse<DocumentPhysiqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPhysiqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiqueIdPut$Plain(params: {
    id: number;
    body?: DocumentPhysiqueDto
  }): Observable<DocumentPhysiqueDto> {

    return this.apiDocumentPhysiqueIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPhysiqueDto>) => r.body as DocumentPhysiqueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiqueIdPut$Json$Response(params: {
    id: number;
    body?: DocumentPhysiqueDto
  }): Observable<StrictHttpResponse<DocumentPhysiqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentPhysiqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentPhysiqueIdPut$Json(params: {
    id: number;
    body?: DocumentPhysiqueDto
  }): Observable<DocumentPhysiqueDto> {

    return this.apiDocumentPhysiqueIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentPhysiqueDto>) => r.body as DocumentPhysiqueDto)
    );
  }

  /**
   * Path part for operation apiDocumentPhysiqueIdDelete
   */
  static readonly ApiDocumentPhysiqueIdDeletePath = '/api/DocumentPhysique/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentPhysiqueIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentPhysiqueService.ApiDocumentPhysiqueIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentPhysiqueIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentPhysiqueIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiDocumentPhysiqueIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
