/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InterventionArea } from '../models/intervention-area';
import { InterventionAreaDto } from '../models/intervention-area-dto';

@Injectable({
  providedIn: 'root',
})
export class InterventionAreaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiInterventionAreaForCurrentUserGet
   */
  static readonly ApiInterventionAreaForCurrentUserGetPath = '/api/InterventionArea/ForCurrentUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaForCurrentUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaForCurrentUserGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<InterventionArea>>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaForCurrentUserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InterventionArea>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaForCurrentUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaForCurrentUserGet$Plain(params?: {
  }): Observable<Array<InterventionArea>> {

    return this.apiInterventionAreaForCurrentUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InterventionArea>>) => r.body as Array<InterventionArea>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaForCurrentUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaForCurrentUserGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<InterventionArea>>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaForCurrentUserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InterventionArea>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaForCurrentUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaForCurrentUserGet$Json(params?: {
  }): Observable<Array<InterventionArea>> {

    return this.apiInterventionAreaForCurrentUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InterventionArea>>) => r.body as Array<InterventionArea>)
    );
  }

  /**
   * Path part for operation apiInterventionAreaGet
   */
  static readonly ApiInterventionAreaGetPath = '/api/InterventionArea';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<InterventionAreaDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InterventionAreaDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<InterventionAreaDto>> {

    return this.apiInterventionAreaGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InterventionAreaDto>>) => r.body as Array<InterventionAreaDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<InterventionAreaDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InterventionAreaDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<InterventionAreaDto>> {

    return this.apiInterventionAreaGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InterventionAreaDto>>) => r.body as Array<InterventionAreaDto>)
    );
  }

  /**
   * Path part for operation apiInterventionAreaPost
   */
  static readonly ApiInterventionAreaPostPath = '/api/InterventionArea';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaPost$Plain$Response(params?: {
    body?: InterventionAreaDto
  }): Observable<StrictHttpResponse<InterventionAreaDto>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionAreaDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaPost$Plain(params?: {
    body?: InterventionAreaDto
  }): Observable<InterventionAreaDto> {

    return this.apiInterventionAreaPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionAreaDto>) => r.body as InterventionAreaDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaPost$Json$Response(params?: {
    body?: InterventionAreaDto
  }): Observable<StrictHttpResponse<InterventionAreaDto>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionAreaDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaPost$Json(params?: {
    body?: InterventionAreaDto
  }): Observable<InterventionAreaDto> {

    return this.apiInterventionAreaPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionAreaDto>) => r.body as InterventionAreaDto)
    );
  }

  /**
   * Path part for operation apiInterventionAreaIdGet
   */
  static readonly ApiInterventionAreaIdGetPath = '/api/InterventionArea/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<InterventionAreaDto>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionAreaDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaIdGet$Plain(params: {
    id: number;
  }): Observable<InterventionAreaDto> {

    return this.apiInterventionAreaIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionAreaDto>) => r.body as InterventionAreaDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<InterventionAreaDto>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionAreaDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaIdGet$Json(params: {
    id: number;
  }): Observable<InterventionAreaDto> {

    return this.apiInterventionAreaIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionAreaDto>) => r.body as InterventionAreaDto)
    );
  }

  /**
   * Path part for operation apiInterventionAreaIdPut
   */
  static readonly ApiInterventionAreaIdPutPath = '/api/InterventionArea/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaIdPut$Plain$Response(params: {
    id: number;
    body?: InterventionAreaDto
  }): Observable<StrictHttpResponse<InterventionAreaDto>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionAreaDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaIdPut$Plain(params: {
    id: number;
    body?: InterventionAreaDto
  }): Observable<InterventionAreaDto> {

    return this.apiInterventionAreaIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionAreaDto>) => r.body as InterventionAreaDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaIdPut$Json$Response(params: {
    id: number;
    body?: InterventionAreaDto
  }): Observable<StrictHttpResponse<InterventionAreaDto>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionAreaDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiInterventionAreaIdPut$Json(params: {
    id: number;
    body?: InterventionAreaDto
  }): Observable<InterventionAreaDto> {

    return this.apiInterventionAreaIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionAreaDto>) => r.body as InterventionAreaDto)
    );
  }

  /**
   * Path part for operation apiInterventionAreaIdDelete
   */
  static readonly ApiInterventionAreaIdDeletePath = '/api/InterventionArea/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInterventionAreaIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InterventionAreaService.ApiInterventionAreaIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInterventionAreaIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInterventionAreaIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiInterventionAreaIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
