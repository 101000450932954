import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()

export class Cst {
  public static WebServices = {
    url: environment.WebServiceUrl,
    method: {
      Company: '/api/Company',
      employee: '/api/Employee',
      interventionAreas: '/api/InterventionAreas',
      licences: '/api/Licences',
      riskPreventionPlan: '/api/RiskPreventionPlans',
      lastUpdatedDocuments: '/api/form/gethistory', //
      postLicence: '/api/Licence/PostLicence',
      getQualif:'/api/Qualification',
    },
    errors: {
      lanError: 'Erreur de communication avec le serveur de données.',
      login : '/Users/authenticate',
      postLicence: '/api/Licence/PostLicence',
    }
  };
  public static TypesDocuments = {
    planPrevention: 'PP',
    societe: 'Company',
    permisJournalier: 'PJ',
    permisHauteur: 'PH',
    permisConsignation: 'PE',
    permisFeu: 'PF',
    permisConfinement: 'PC'
  };
  public static TypesDocumentsImagesNames = ['','PP','SOC','PJ','PH','PE','PF','PC'];

  public static Routes = {
    AddPlanDePrevention: 'pages/permis/planprevention/add',
    AddPermis: 'pages/permis/journalier/add',
    AddPermisFeu: 'pages/permis/feu/add',
    AddPermisHauteur: 'pages/permis/hauteur/add',
    AddPermisElec: 'pages/permis/consignation/add',
    AddPermisConfine: 'pages/permis/confine/add',
    AddEntreprise: 'pages/entreprise/add',
    ViewEntreprise: 'pages/entreprise/view/',
    EditPlanDePrevention: 'pages/permis/planprevention/edit/',
    EditPermis: 'pages/permis/journalier/edit/',
    EditPermisFeu: 'pages/permis/feu/edit/',
    EditPermisHauteur: 'pages/permis/hauteur/edit/',
    EditPermisElec: 'pages/permis/consignation/edit/',
    EditPermisConfine: 'pages/permis/confine/edit/',
    EditEntreprise: 'pages/entreprise/edit/',
    AddSalarie: 'pages/salarie/add/{id}',
    Login: 'pages/login',
    AddDocument: 'newDocument',
    LastUpdates: 'lastupdates'
  };

}
