import { Component, OnInit, EventEmitter, Input, Output, Type } from '@angular/core';
import { Cst } from 'src/app/shared/utils/cst';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DonneurOrdreDto, InterventionAreaDto, TypeForm } from 'src/app/shared/models';
import { Observable, Subject, zip } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { PermisService } from 'src/app/Pages/permis/Shared/Permis.service';
import { InterventionAreaService } from 'src/app/shared/services/intervention-area.service';
import { LocalStorageService } from 'src/app/Pages/local-storage.service';
import { DonneurOrdreService } from 'src/app/shared/services/donneur-ordre.service';
import { map, takeUntil, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-filtre-documents',
  templateUrl: './filtre-documents.component.html',
  styleUrls: ['./filtre-documents.component.css'],
})
export class FiltreDocumentsComponent implements OnInit {

  ngUnsubscribe = new Subject();
  @Output() applyFilterCompany: EventEmitter<any> = new EventEmitter();
  @Output() applyFilterDocument: EventEmitter<any> = new EventEmitter();
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'top';
  @Input() stateVisu: boolean = true;
  @Input() defaultFilterTypeDocument: TypeForm[] = [];
  @Output() stateVisuChange: EventEmitter<boolean> = new EventEmitter();
  donneursOrdre: DonneurOrdreDto[] = [];
  donneursOrdreAutocomplete: Observable<DonneurOrdreDto[]>;
  lieuxIntervention: InterventionAreaDto[] = [];
  lieuxInterventionAutocomplete: Observable<InterventionAreaDto[]>;
  idDOUserConnected = null;

  formFilter = new FormGroup({
    dateIntervention: new FormControl(null),
    nature: new FormControl(''),
    societe: new FormControl(''),
    donneurOrdreID: new FormControl(null),
    lieuInterventionID: new FormControl(null),
    plandePreventions: new FormControl(true),
    permisJournaliers: new FormControl(true),
    permisSpeFeu: new FormControl(true),
    permisSpeHauteur: new FormControl(true),
    permisSpeElec: new FormControl(true),
    permisSepConfi: new FormControl(true)
  })

  searchSelectItems = [
    {
      value: Cst.TypesDocuments.planPrevention,
      viewValue: 'Un Plan de prévention',
    },
    { value: Cst.TypesDocuments.societe, viewValue: 'Une société' },
    { value: Cst.TypesDocuments.permisJournalier, viewValue: 'Un permis journalier' },
    { value: Cst.TypesDocuments.permisFeu, viewValue: "Permis 'Feu'" },
    {
      value: Cst.TypesDocuments.permisHauteur,
      viewValue: "Permis 'Travail en hauteur'",
    },
    {
      value: Cst.TypesDocuments.permisConsignation,
      viewValue: "Permis 'Consignation'",
    },
    {
      value: Cst.TypesDocuments.permisConfinement,
      viewValue: "Permis 'Espaces Confinés'",
    },
  ];

  constructor(private _snackBar: MatSnackBar,
    private permisService: PermisService,
    private interventionAreaService: InterventionAreaService,
    private localStorageService: LocalStorageService,
    private donneurOrdreService: DonneurOrdreService
  ) { }

  ngOnInit(): void {
    // Récupère identifiant DO si utilisateur connecté est un DO (role = 'default') : sélectionné DO par défaut dans la recherche
    const user = JSON.parse( this.localStorageService.getItem('user'));
    if(user.userRoleId === "default" && user.person) {
      this.idDOUserConnected = user.person.idPerson;
    }

    zip(
      this.donneurOrdreService.apiDonneurOrdreGet$Json(),
      this.interventionAreaService.apiInterventionAreaForCurrentUserGet$Json()
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([donneursordre, lieuxIntervention]) => {
        this.donneursOrdre = donneursordre.sort(
          this.permisService.toSortDonneurOrdreOnLastnameFirstName
        );

        // Récupérer la liste des lieux d'intervention pour l'autocomplétion du filtre
        // Uniquement ceux correspondant à l'entité de l'utilisateur connecté
        this.lieuxIntervention = lieuxIntervention
          .sort((a, b) => {
            if (a.details < b.details) {
              return -1;
            } else if (a.details > b.details) {
              return 1;
            } else {
              return 0;
            }
          });
        this.initFilterWithDefaultValues();
      });

    // Filtre de l'autocomplete sur la liste des donneurs d'ordre
    this.donneursOrdreAutocomplete = this.formFilter.get('donneurOrdreID')?.valueChanges.pipe(
      startWith(''),
      map(value => this.donneursOrdre.filter(don => (don.lastName.toLowerCase() + ' ' + don.firstName.toLowerCase()).includes(value.toString().toLowerCase()))),
    );

    // Filtre de l'autocomplete sur la liste des lieu d'intervention
    this.lieuxInterventionAutocomplete = this.formFilter.get('lieuInterventionID')?.valueChanges.pipe(
      startWith(''),
      map(value => this.lieuxIntervention.filter(lieu => lieu.details.toLowerCase().includes(value.toString().toLowerCase())))
    );
  }

  initFilterWithDefaultValues(): void {
    if(this.idDOUserConnected) {
      this.formFilter.get('donneurOrdreID').setValue(this.idDOUserConnected);
    }
    this.formFilter.get('plandePreventions').setValue(this.defaultFilterTypeDocument.some(t => t == TypeForm.Pp));
    this.formFilter.get('permisJournaliers').setValue(this.defaultFilterTypeDocument.some(t => t == TypeForm.Pj));
    this.formFilter.get('permisSpeFeu').setValue(this.defaultFilterTypeDocument.some(t => t == TypeForm.Pf));
    this.formFilter.get('permisSpeHauteur').setValue(this.defaultFilterTypeDocument.some(t => t == TypeForm.Ph));
    this.formFilter.get('permisSpeElec').setValue(this.defaultFilterTypeDocument.some(t => t == TypeForm.Pe));
    this.formFilter.get('permisSepConfi').setValue(this.defaultFilterTypeDocument.some(t => t == TypeForm.Pc));
  }

  onApplyFilterCompany() {
    let filterToSend = {
      societe: ''
    }
    filterToSend.societe = this.formFilter.value.societe;
    this.applyFilterCompany.emit(filterToSend)
  }

  onApplyFilterDocument() {
    let filterToSend = {
      typeForm: [],
      dateIntervention: null,
      nature: '',
      societe: '',
      donneurOrdreID: null,
      lieuInterventionID: null
    }
    if (this.formFilter.value.permisJournaliers) {
      filterToSend.typeForm.push(TypeForm.Pj);
    }
    if (this.formFilter.value.plandePreventions) {
      filterToSend.typeForm.push(TypeForm.Pp);
    }
    if (this.formFilter.value.permisSepConfi) {
      filterToSend.typeForm.push(TypeForm.Pc);
    }
    if (this.formFilter.value.permisSpeElec) {
      filterToSend.typeForm.push(TypeForm.Pe);
    }
    if (this.formFilter.value.permisSpeFeu) {
      filterToSend.typeForm.push(TypeForm.Pf);
    }
    if (this.formFilter.value.permisSpeHauteur) {
      filterToSend.typeForm.push(TypeForm.Ph);
    }
    if (this.formFilter.value.dateIntervention) {
      filterToSend.dateIntervention = new Date(this.formFilter.value.dateIntervention);
    }
    filterToSend.nature = this.formFilter.value.nature;
    filterToSend.societe = this.formFilter.value.societe;
    filterToSend.donneurOrdreID = this.formFilter.value.donneurOrdreID;
    filterToSend.lieuInterventionID = this.formFilter.value.lieuInterventionID;

    this.applyFilterDocument.emit(filterToSend)
  }

  toastAffiche(libelle: string) {
    this._snackBar.open(libelle, '', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  onToggleVisuChange(state: boolean) {
    this.stateVisu = state;
    this.stateVisuChange.emit(state);
  }

  emitFilter() {
    //si recherche sur entreprises
    if (this.stateVisu) {
      this.onApplyFilterCompany();
    }
    //si recherche sur documents
    else {
      //vérification qu'au moins un type de documents est dans les filtres
      if (!this.formFilter.value.permisJournaliers
        && !this.formFilter.value.plandePreventions
        && !this.formFilter.value.permisSepConfi
        && !this.formFilter.value.permisSpeElec
        && !this.formFilter.value.permisSpeFeu
        && !this.formFilter.value.permisSpeHauteur) {
        this.toastAffiche('Vous devez au moins sélectionner un type de formulaire à afficher');
      }
      else {
        this.onApplyFilterDocument();
      }
    }
  }

  //#region Donneur d'ordre
  /**
   * Retourne la chaine de caractères à afficher dans le select du donneur d'ordre
  */
  displayDOName(idDonneurOrdre: number): string {
    const donneurOrdre = this.donneursOrdre.find(don => don.idDonneurOrdre == idDonneurOrdre);

    return idDonneurOrdre && donneurOrdre
      ? donneurOrdre.lastName + ' ' + donneurOrdre.firstName
      : '';
  }
  //#endregion

  //#region Lieu d'intervention
  /**
   * Retourne la chaine de caractères à afficher dans le select du lieu d'intervention
  */
   displayLieuName(idLieuIntervention: number): string {
    const lieuIntervention = this.lieuxIntervention.find(lieu => lieu.idInterventionArea == idLieuIntervention);

    return idLieuIntervention && lieuIntervention
      ? lieuIntervention.details
      : '';
  }
  //#endregion

}
