import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastUpdateDocument } from 'src/app/shared/models/custom models/lastUpdateDocument.model';
import { StrictHttpResponse } from 'src/app/shared/strict-http-response';
import { FormService } from 'src/app/shared/services/form.service';
import { FormHistoriesDto } from 'src/app/shared/models/form-histories-dto';
import { LocalStorageService } from 'src/app/Pages/local-storage.service';
import { DatePipe } from '@angular/common';
import { Cst } from 'src/app/shared/utils/cst';
import { CompanySearchResultDto, TypeForm } from 'src/app/shared/models';
import { CompanyService } from 'src/app/shared/services';
import { lastUpdateCompany } from 'src/app/shared/models/custom models/lastUpdateCompany.model';

@Component({
  selector: 'app-last-updates',
  templateUrl: './last-updates.component.html',
  styleUrls: ['./last-updates.component.css'],
})
export class LastUpdatesComponent implements OnInit {
  list: number[]= [];
  filterDocuments = {
    typeForm: [
      TypeForm.Pp,
      TypeForm.Pj,
      TypeForm.Pf,
      TypeForm.Pc,
      TypeForm.Pe,
      TypeForm.Ph
    ],
    dateIntervention: '',
    nature: null,
    societe: null,
    donneurOrdreID: null,
    lieuInterventionID: null
  };

  filterCompanies = {
    societe: null
  }
  documents: lastUpdateDocument[] = [];
  companies: lastUpdateCompany[] = [];
  stateVisu: boolean = false;
  isCompaniesSearchLaunched: boolean = false;
  isDocumentsSearchLaunched: boolean = false;

  constructor(
    private dataservice: FormService,
    private datacompservice : CompanyService,
    private router: Router,
    private datePipe: DatePipe,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    // Récupère identifiant DO si utilisateur connecté est un DO (role = 'default') : sélectionné DO par défaut dans la recherche
    const user = JSON.parse( this.localStorageService.getItem('user'));
    if(user.userRoleId === "default" && user.person) {
      this.filterDocuments.donneurOrdreID = user.person.idPerson;
    }

    this.readDataDocuments();
    this.localStorageService.removeItem('permis');
    this.localStorageService.removeItem('permisElec');
    this.localStorageService.removeItem('permisConfine');
    this.localStorageService.removeItem('permisHauteur');
    this.localStorageService.removeItem('permisFeu');
    this.localStorageService.removeItem('mesures');
    this.localStorageService.removeItem('mesureslnk');
    this.localStorageService.removeItem('lnkPJDocument');
    this.localStorageService.removeItem('Document');
    this.localStorageService.removeItem('planPrevention');
    this.localStorageService.removeItem('permisJournalier');

  }

  readDataDocuments() {
    let result: lastUpdateDocument;
    this.dataservice
      .apiFormHistoryGet$Json$Response({
        typeForms: this.filterDocuments.typeForm,
        dateIntervention: this.datePipe.transform(
          this.filterDocuments.dateIntervention,
          'yyyy-MM-dd'
        ),
        nature: this.filterDocuments.nature,
        nomSociete: this.filterDocuments.societe,
        idDonneurOrdre: this.filterDocuments.donneurOrdreID,
        idLieuIntervention: this.filterDocuments.lieuInterventionID
      })
      .subscribe((docs: StrictHttpResponse<FormHistoriesDto>) => {
        this.documents = [];

        docs.body.histories.forEach((e) => {
          result = new lastUpdateDocument({
            idForm: e.idForm,
            typeForm: e.typeForm,
            intervention: e.intervention,
            companyNom: e.companyName,
            interventionDateDebut: e.interventionDateDebut != null ? new Date(e.interventionDateDebut) : null,
            interventionDateFin: e.interventionDateFin != null ? new Date(e.interventionDateFin) : null,
            modificationDate: new Date(e.modificationDate),
            donneurOrdreName: e.donneurOrdreName,
            interventionAreaName: e.interventionAreaName
          });
          this.documents.push(result);
        });
        this.isDocumentsSearchLaunched = true;
      });
  }


  readDataCompanies() {
      let resultComp : lastUpdateCompany
      this.datacompservice.apiCompanySearchGet$Json$Response({
        name: this.filterCompanies.societe,
      }).subscribe((compData:StrictHttpResponse<CompanySearchResultDto[]>) => {
        this.companies = []
        compData.body.forEach((c) => {
        resultComp = new lastUpdateCompany({
          createdFormTypes: c.createdFormTypes,
          idCompany: c.idCompany,
          modificationDate: new Date(c.modificationDate),
          companyNom: c.nom
        });
        this.companies.push(resultComp);
        });
        this.companies.sort((cmp1, cmp2) => {
          if (cmp1.companyNom < cmp2.companyNom) {
            return -1;
          } else if (cmp1.companyNom > cmp2.companyNom) {
            return 1;
          } else {
            return 0;
          }
        })
        this.isCompaniesSearchLaunched = true;
    });
  }

  onToggleVisuChange(stateVisu: boolean) {
    if(stateVisu){
      this.readDataCompanies();
    }
    else {
      this.readDataDocuments();
    }
  }

  onApplyFilterCompany(filter: any) {
    this.filterCompanies.societe = filter.societe;
    this.readDataCompanies();
  }

  onApplyFilterDocument(filter: any) {
    this.filterDocuments.typeForm = filter.typeForm;
    this.filterDocuments.dateIntervention = filter.dateIntervention;
    this.filterDocuments.nature = filter.nature;
    this.filterDocuments.societe = filter.societe;
    this.filterDocuments.donneurOrdreID = filter.donneurOrdreID;
    this.filterDocuments.lieuInterventionID = filter.lieuInterventionID;
    this.readDataDocuments();
  }


  editSociete(societe: lastUpdateCompany) {
    this.router.navigateByUrl(Cst.Routes.ViewEntreprise + societe.idCompany);
  }


  editDocument(doc: lastUpdateDocument) {
    switch (doc.typeForm) {
      case Cst.TypesDocuments.planPrevention:
        //this.router.navigateByUrl('/preventionPlan/' + doc.idForm);
        this.router.navigateByUrl(
          Cst.Routes.EditPlanDePrevention + doc.idForm
        );
        break;
      case Cst.TypesDocuments.permisJournalier:
        this.router.navigateByUrl(Cst.Routes.EditPermis + doc.idForm);
        break;
      case Cst.TypesDocuments.permisFeu:
        this.router.navigateByUrl(Cst.Routes.EditPermisFeu + doc.idForm);
        break;
      case Cst.TypesDocuments.permisHauteur:
        this.router.navigateByUrl(Cst.Routes.EditPermisHauteur + doc.idForm);
        break;
      case Cst.TypesDocuments.permisConsignation:
        this.router.navigateByUrl(Cst.Routes.EditPermisElec + doc.idForm);
        break;
      case Cst.TypesDocuments.permisConfinement:
        this.router.navigateByUrl(Cst.Routes.EditPermisConfine + doc.idForm);
        break;
      default:
        break;
    }
  }

  archiveDocument(doc: lastUpdateDocument) {}

  detailsSociete(doc: lastUpdateDocument) {
    this.router.navigateByUrl('/pages/entreprise/view/' + doc.idForm);
  }
}
