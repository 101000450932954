import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'lu-date-picker',
  templateUrl: './lu-date-picker.component.html',
  styleUrls: ['./lu-date-picker.component.css'],
  
})
export class LuDatePickerComponent implements OnInit {
  @Input() PlaceHolder = "";
  @Output() InputDate = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.InputDate.emit(event.value);
  }

}
