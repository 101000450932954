/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PostMailChsctdto } from '../models/post-mail-chsctdto';
import { RiskPreventionPlanDto } from '../models/risk-prevention-plan-dto';

@Injectable({
  providedIn: 'root',
})
export class PlansPreventionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPlansPreventionGet
   */
  static readonly ApiPlansPreventionGetPath = '/api/PlansPrevention';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<RiskPreventionPlanDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiskPreventionPlanDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<RiskPreventionPlanDto>> {

    return this.apiPlansPreventionGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiskPreventionPlanDto>>) => r.body as Array<RiskPreventionPlanDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<RiskPreventionPlanDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiskPreventionPlanDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<RiskPreventionPlanDto>> {

    return this.apiPlansPreventionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiskPreventionPlanDto>>) => r.body as Array<RiskPreventionPlanDto>)
    );
  }

  /**
   * Path part for operation apiPlansPreventionPost
   */
  static readonly ApiPlansPreventionPostPath = '/api/PlansPrevention';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionPost$Plain$Response(params?: {
    body?: RiskPreventionPlanDto
  }): Observable<StrictHttpResponse<RiskPreventionPlanDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RiskPreventionPlanDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionPost$Plain(params?: {
    body?: RiskPreventionPlanDto
  }): Observable<RiskPreventionPlanDto> {

    return this.apiPlansPreventionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RiskPreventionPlanDto>) => r.body as RiskPreventionPlanDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionPost$Json$Response(params?: {
    body?: RiskPreventionPlanDto
  }): Observable<StrictHttpResponse<RiskPreventionPlanDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RiskPreventionPlanDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionPost$Json(params?: {
    body?: RiskPreventionPlanDto
  }): Observable<RiskPreventionPlanDto> {

    return this.apiPlansPreventionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RiskPreventionPlanDto>) => r.body as RiskPreventionPlanDto)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdGet
   */
  static readonly ApiPlansPreventionIdGetPath = '/api/PlansPrevention/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RiskPreventionPlanDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RiskPreventionPlanDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdGet$Plain(params: {
    id: number;
  }): Observable<RiskPreventionPlanDto> {

    return this.apiPlansPreventionIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RiskPreventionPlanDto>) => r.body as RiskPreventionPlanDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RiskPreventionPlanDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RiskPreventionPlanDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdGet$Json(params: {
    id: number;
  }): Observable<RiskPreventionPlanDto> {

    return this.apiPlansPreventionIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RiskPreventionPlanDto>) => r.body as RiskPreventionPlanDto)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdPut
   */
  static readonly ApiPlansPreventionIdPutPath = '/api/PlansPrevention/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdPut$Plain$Response(params: {
    id: number;
    body?: RiskPreventionPlanDto
  }): Observable<StrictHttpResponse<RiskPreventionPlanDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RiskPreventionPlanDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdPut$Plain(params: {
    id: number;
    body?: RiskPreventionPlanDto
  }): Observable<RiskPreventionPlanDto> {

    return this.apiPlansPreventionIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RiskPreventionPlanDto>) => r.body as RiskPreventionPlanDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdPut$Json$Response(params: {
    id: number;
    body?: RiskPreventionPlanDto
  }): Observable<StrictHttpResponse<RiskPreventionPlanDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RiskPreventionPlanDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdPut$Json(params: {
    id: number;
    body?: RiskPreventionPlanDto
  }): Observable<RiskPreventionPlanDto> {

    return this.apiPlansPreventionIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RiskPreventionPlanDto>) => r.body as RiskPreventionPlanDto)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdDelete
   */
  static readonly ApiPlansPreventionIdDeletePath = '/api/PlansPrevention/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiPlansPreventionIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdGetDirecctDocumentGet
   */
  static readonly ApiPlansPreventionIdGetDirecctDocumentGetPath = '/api/PlansPrevention/{id}/GetDIRECCTDocument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdGetDirecctDocumentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdGetDirecctDocumentGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdGetDirecctDocumentGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdGetDirecctDocumentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdGetDirecctDocumentGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiPlansPreventionIdGetDirecctDocumentGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGet
   */
  static readonly ApiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGetPath = '/api/PlansPrevention/{id}/SendDIRECCTDocumentAsPDFByEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGet(params: {
    id: number;
  }): Observable<void> {

    return this.apiPlansPreventionIdSendDirecctDocumentAsPdfByEmailGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdReportGet
   */
  static readonly ApiPlansPreventionIdReportGetPath = '/api/PlansPrevention/{id}/Report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdReportGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdReportGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPlansPreventionIdReportGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiPlansPreventionIdReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdSendMailToChsctPost
   */
  static readonly ApiPlansPreventionIdSendMailToChsctPostPath = '/api/PlansPrevention/{id}/SendMailToCHSCT';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdSendMailToChsctPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdSendMailToChsctPost$Response(params: {
    id: number;
    body?: PostMailChsctdto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdSendMailToChsctPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdSendMailToChsctPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdSendMailToChsctPost(params: {
    id: number;
    body?: PostMailChsctdto
  }): Observable<void> {

    return this.apiPlansPreventionIdSendMailToChsctPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiPlansPreventionIdSendPpDocumentsEmailCompanyPost
   */
  static readonly ApiPlansPreventionIdSendPpDocumentsEmailCompanyPostPath = '/api/PlansPrevention/{id}/SendPPDocumentsEmail/{company}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPlansPreventionIdSendPpDocumentsEmailCompanyPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdSendPpDocumentsEmailCompanyPost$Response(params: {
    id: number;
    company: number;
    body?: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlansPreventionService.ApiPlansPreventionIdSendPpDocumentsEmailCompanyPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('company', params.company, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPlansPreventionIdSendPpDocumentsEmailCompanyPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPlansPreventionIdSendPpDocumentsEmailCompanyPost(params: {
    id: number;
    company: number;
    body?: Array<string>
  }): Observable<void> {

    return this.apiPlansPreventionIdSendPpDocumentsEmailCompanyPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
