/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DangerDto } from '../models/danger-dto';
import { SituationTravailDto } from '../models/situation-travail-dto';
import { TypeForm } from '../models/type-form';

@Injectable({
  providedIn: 'root',
})
export class SituationTravailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSituationTravailGet
   */
  static readonly ApiSituationTravailGetPath = '/api/SituationTravail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SituationTravailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SituationTravailDto>> {

    return this.apiSituationTravailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailDto>>) => r.body as Array<SituationTravailDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SituationTravailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SituationTravailDto>> {

    return this.apiSituationTravailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailDto>>) => r.body as Array<SituationTravailDto>)
    );
  }

  /**
   * Path part for operation apiSituationTravailPost
   */
  static readonly ApiSituationTravailPostPath = '/api/SituationTravail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailPost$Plain$Response(params?: {
    body?: SituationTravailDto
  }): Observable<StrictHttpResponse<SituationTravailDto>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SituationTravailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailPost$Plain(params?: {
    body?: SituationTravailDto
  }): Observable<SituationTravailDto> {

    return this.apiSituationTravailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SituationTravailDto>) => r.body as SituationTravailDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailPost$Json$Response(params?: {
    body?: SituationTravailDto
  }): Observable<StrictHttpResponse<SituationTravailDto>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SituationTravailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailPost$Json(params?: {
    body?: SituationTravailDto
  }): Observable<SituationTravailDto> {

    return this.apiSituationTravailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SituationTravailDto>) => r.body as SituationTravailDto)
    );
  }

  /**
   * Path part for operation apiSituationTravailIdGet
   */
  static readonly ApiSituationTravailIdGetPath = '/api/SituationTravail/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SituationTravailDto>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SituationTravailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdGet$Plain(params: {
    id: number;
  }): Observable<SituationTravailDto> {

    return this.apiSituationTravailIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SituationTravailDto>) => r.body as SituationTravailDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SituationTravailDto>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SituationTravailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdGet$Json(params: {
    id: number;
  }): Observable<SituationTravailDto> {

    return this.apiSituationTravailIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SituationTravailDto>) => r.body as SituationTravailDto)
    );
  }

  /**
   * Path part for operation apiSituationTravailIdPut
   */
  static readonly ApiSituationTravailIdPutPath = '/api/SituationTravail/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailIdPut$Plain$Response(params: {
    id: number;
    body?: SituationTravailDto
  }): Observable<StrictHttpResponse<SituationTravailDto>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SituationTravailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailIdPut$Plain(params: {
    id: number;
    body?: SituationTravailDto
  }): Observable<SituationTravailDto> {

    return this.apiSituationTravailIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SituationTravailDto>) => r.body as SituationTravailDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailIdPut$Json$Response(params: {
    id: number;
    body?: SituationTravailDto
  }): Observable<StrictHttpResponse<SituationTravailDto>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SituationTravailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSituationTravailIdPut$Json(params: {
    id: number;
    body?: SituationTravailDto
  }): Observable<SituationTravailDto> {

    return this.apiSituationTravailIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SituationTravailDto>) => r.body as SituationTravailDto)
    );
  }

  /**
   * Path part for operation apiSituationTravailIdDelete
   */
  static readonly ApiSituationTravailIdDeletePath = '/api/SituationTravail/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiSituationTravailIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiSituationTravailGetAllbyFormGet
   */
  static readonly ApiSituationTravailGetAllbyFormGetPath = '/api/SituationTravail/GetAllbyForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailGetAllbyFormGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGetAllbyFormGet$Plain$Response(params?: {
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<SituationTravailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailGetAllbyFormGetPath, 'get');
    if (params) {
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailGetAllbyFormGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGetAllbyFormGet$Plain(params?: {
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<SituationTravailDto>> {

    return this.apiSituationTravailGetAllbyFormGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailDto>>) => r.body as Array<SituationTravailDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailGetAllbyFormGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGetAllbyFormGet$Json$Response(params?: {
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<SituationTravailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailGetAllbyFormGetPath, 'get');
    if (params) {
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailGetAllbyFormGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailGetAllbyFormGet$Json(params?: {
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<SituationTravailDto>> {

    return this.apiSituationTravailGetAllbyFormGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailDto>>) => r.body as Array<SituationTravailDto>)
    );
  }

  /**
   * Path part for operation apiSituationTravailIdDangerGet
   */
  static readonly ApiSituationTravailIdDangerGetPath = '/api/SituationTravail/{id}/Danger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdDangerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<DangerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdDangerGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DangerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdDangerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGet$Plain(params: {
    id: number;
  }): Observable<Array<DangerDto>> {

    return this.apiSituationTravailIdDangerGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DangerDto>>) => r.body as Array<DangerDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdDangerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<DangerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdDangerGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DangerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdDangerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGet$Json(params: {
    id: number;
  }): Observable<Array<DangerDto>> {

    return this.apiSituationTravailIdDangerGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DangerDto>>) => r.body as Array<DangerDto>)
    );
  }

  /**
   * Path part for operation apiSituationTravailIdDangerGetAllByFormGet
   */
  static readonly ApiSituationTravailIdDangerGetAllByFormGetPath = '/api/SituationTravail/{id}/Danger/GetAllByForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdDangerGetAllByFormGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGetAllByFormGet$Plain$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<DangerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdDangerGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DangerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdDangerGetAllByFormGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGetAllByFormGet$Plain(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<DangerDto>> {

    return this.apiSituationTravailIdDangerGetAllByFormGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DangerDto>>) => r.body as Array<DangerDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSituationTravailIdDangerGetAllByFormGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGetAllByFormGet$Json$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<DangerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SituationTravailService.ApiSituationTravailIdDangerGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DangerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSituationTravailIdDangerGetAllByFormGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSituationTravailIdDangerGetAllByFormGet$Json(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<DangerDto>> {

    return this.apiSituationTravailIdDangerGetAllByFormGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DangerDto>>) => r.body as Array<DangerDto>)
    );
  }

}
