<div [formGroup]="formFilter">
  <div class="d-flex">
    <div class="d-flex divToggleButtons">
      <div class="btn-group btn-group-toggle" ngbRadioGroup>
        <label ngbButtonLabel class="bouttonRadio" [class.active]="stateVisu">
          <input ngbButton type="radio" (click)="onToggleVisuChange(true)"/> Entreprises
        </label>
        <label ngbButtonLabel class="bouttonRadio" [class.active]="!stateVisu">
          <input ngbButton type="radio" (click)="onToggleVisuChange(false)"/> Plans / Permis
        </label>
      </div>
    </div>
    <div class="divFilterTypesDocuments"  *ngIf="!stateVisu">
        <div><mat-checkbox formControlName="plandePreventions">Plans de prévention</mat-checkbox></div>
        <div><mat-checkbox formControlName="permisJournaliers">Permis journaliers</mat-checkbox></div>
        <div><mat-checkbox formControlName="permisSepConfi">Espaces confinés</mat-checkbox></div>
        <div><mat-checkbox formControlName="permisSpeElec">Consignation</mat-checkbox></div>
        <div><mat-checkbox formControlName="permisSpeHauteur">Travail en hauteur</mat-checkbox></div>
        <div><mat-checkbox formControlName="permisSpeFeu">Feu</mat-checkbox></div>
    </div>
  </div>
  <div class="d-flex divFiltersFields">
    <div class="width33" *ngIf="!stateVisu">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>Nature intervention </mat-label>
        <input matInput #saisieNature PlaceHolder="Nature de l'intervention" formControlName="nature">
      </mat-form-field>
    </div>
    <div [ngClass]="{'width33 pl-4': !stateVisu, 'w-100' : stateVisu }">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>Nom société </mat-label>
        <input matInput #saisieNom PlaceHolder="Nom société" formControlName="societe">
      </mat-form-field>
    </div>
    <div class="width33 pl-4" *ngIf="!stateVisu">
      <mat-form-field appearance="legacy"  class="w-100">
        <mat-label> Date intervention </mat-label>
        <input matInput [matDatepicker]="picker1" PlaceHolder="Date intervention" formControlName="dateIntervention" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex mt-2">
    <!-- <div class="d-flex"> -->
    <mat-form-field appearance="legacy" class="w-100">
      <mat-label>Donneur d'ordre</mat-label>
      <input
        type="text"
        aria-label="Donneur d'ordre"
        matInput
        formControlName="donneurOrdreID"
        [matAutocomplete]="autoDO">
      <mat-autocomplete #autoDO="matAutocomplete" [displayWith]="displayDOName.bind(this)">
        <mat-option *ngFor="let do of donneursOrdreAutocomplete | async" [value]="do.idDonneurOrdre">
          {{ do.lastName + ' ' + do.firstName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- </div> -->
    <mat-form-field appearance="legacy" class="w-100">
      <mat-label>Lieu d'intervention</mat-label>
      <input
        type="text"
        aria-label="Lieu d'intervention"
        matInput
        formControlName="lieuInterventionID"
        [matAutocomplete]="autoIA">
      <mat-autocomplete #autoIA="matAutocomplete" [displayWith]="displayLieuName.bind(this)">
        <mat-option *ngFor="let lieuIntervention of lieuxInterventionAutocomplete | async" [value]="lieuIntervention.idInterventionArea">
          {{ lieuIntervention.details }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="divSearchButton">
      <img style="cursor:pointer" src="../../../../assets/icones/chercher1.png" title="Filtrer" (click)="emitFilter()">
    </div>
  </div>
</div>
