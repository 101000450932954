/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserRoleDto } from '../models/user-role-dto';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUserRoleGet
   */
  static readonly ApiUserRoleGetPath = '/api/UserRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRoleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<UserRoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserRoleService.ApiUserRoleGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserRoleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRoleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<UserRoleDto>> {

    return this.apiUserRoleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserRoleDto>>) => r.body as Array<UserRoleDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRoleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<UserRoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserRoleService.ApiUserRoleGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserRoleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRoleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<UserRoleDto>> {

    return this.apiUserRoleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserRoleDto>>) => r.body as Array<UserRoleDto>)
    );
  }

  /**
   * Path part for operation apiUserRoleIdGet
   */
  static readonly ApiUserRoleIdGetPath = '/api/UserRole/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRoleIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<UserRoleDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserRoleService.ApiUserRoleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserRoleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRoleIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleIdGet$Plain(params: {
    id: number;
  }): Observable<UserRoleDto> {

    return this.apiUserRoleIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserRoleDto>) => r.body as UserRoleDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRoleIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<UserRoleDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserRoleService.ApiUserRoleIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserRoleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRoleIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRoleIdGet$Json(params: {
    id: number;
  }): Observable<UserRoleDto> {

    return this.apiUserRoleIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserRoleDto>) => r.body as UserRoleDto)
    );
  }

}
