/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsequenceDto } from '../models/consequence-dto';
import { MesurePreventionDto } from '../models/mesure-prevention-dto';
import { TypeForm } from '../models/type-form';

@Injectable({
  providedIn: 'root',
})
export class ConsequenceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiConsequenceGet
   */
  static readonly ApiConsequenceGetPath = '/api/Consequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<ConsequenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ConsequenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<ConsequenceDto>> {

    return this.apiConsequenceGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ConsequenceDto>>) => r.body as Array<ConsequenceDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<ConsequenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ConsequenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<ConsequenceDto>> {

    return this.apiConsequenceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ConsequenceDto>>) => r.body as Array<ConsequenceDto>)
    );
  }

  /**
   * Path part for operation apiConsequencePost
   */
  static readonly ApiConsequencePostPath = '/api/Consequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequencePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequencePost$Plain$Response(params?: {
    body?: ConsequenceDto
  }): Observable<StrictHttpResponse<ConsequenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequencePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConsequenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequencePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequencePost$Plain(params?: {
    body?: ConsequenceDto
  }): Observable<ConsequenceDto> {

    return this.apiConsequencePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConsequenceDto>) => r.body as ConsequenceDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequencePost$Json$Response(params?: {
    body?: ConsequenceDto
  }): Observable<StrictHttpResponse<ConsequenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequencePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConsequenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequencePost$Json(params?: {
    body?: ConsequenceDto
  }): Observable<ConsequenceDto> {

    return this.apiConsequencePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConsequenceDto>) => r.body as ConsequenceDto)
    );
  }

  /**
   * Path part for operation apiConsequenceIdGet
   */
  static readonly ApiConsequenceIdGetPath = '/api/Consequence/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<ConsequenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConsequenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdGet$Plain(params: {
    id: number;
  }): Observable<ConsequenceDto> {

    return this.apiConsequenceIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConsequenceDto>) => r.body as ConsequenceDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<ConsequenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConsequenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdGet$Json(params: {
    id: number;
  }): Observable<ConsequenceDto> {

    return this.apiConsequenceIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConsequenceDto>) => r.body as ConsequenceDto)
    );
  }

  /**
   * Path part for operation apiConsequenceIdPut
   */
  static readonly ApiConsequenceIdPutPath = '/api/Consequence/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequenceIdPut$Plain$Response(params: {
    id: number;
    body?: ConsequenceDto
  }): Observable<StrictHttpResponse<ConsequenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConsequenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequenceIdPut$Plain(params: {
    id: number;
    body?: ConsequenceDto
  }): Observable<ConsequenceDto> {

    return this.apiConsequenceIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConsequenceDto>) => r.body as ConsequenceDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequenceIdPut$Json$Response(params: {
    id: number;
    body?: ConsequenceDto
  }): Observable<StrictHttpResponse<ConsequenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConsequenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConsequenceIdPut$Json(params: {
    id: number;
    body?: ConsequenceDto
  }): Observable<ConsequenceDto> {

    return this.apiConsequenceIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConsequenceDto>) => r.body as ConsequenceDto)
    );
  }

  /**
   * Path part for operation apiConsequenceIdDelete
   */
  static readonly ApiConsequenceIdDeletePath = '/api/Consequence/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiConsequenceIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiConsequenceIdMesurePreventionGet
   */
  static readonly ApiConsequenceIdMesurePreventionGetPath = '/api/Consequence/{id}/MesurePrevention';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdMesurePreventionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<MesurePreventionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdMesurePreventionGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MesurePreventionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdMesurePreventionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGet$Plain(params: {
    id: number;
  }): Observable<Array<MesurePreventionDto>> {

    return this.apiConsequenceIdMesurePreventionGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MesurePreventionDto>>) => r.body as Array<MesurePreventionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdMesurePreventionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<MesurePreventionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdMesurePreventionGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MesurePreventionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdMesurePreventionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGet$Json(params: {
    id: number;
  }): Observable<Array<MesurePreventionDto>> {

    return this.apiConsequenceIdMesurePreventionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MesurePreventionDto>>) => r.body as Array<MesurePreventionDto>)
    );
  }

  /**
   * Path part for operation apiConsequenceIdMesurePreventionGetAllByFormGet
   */
  static readonly ApiConsequenceIdMesurePreventionGetAllByFormGetPath = '/api/Consequence/{id}/MesurePrevention/GetAllByForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdMesurePreventionGetAllByFormGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGetAllByFormGet$Plain$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<MesurePreventionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdMesurePreventionGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MesurePreventionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdMesurePreventionGetAllByFormGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGetAllByFormGet$Plain(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<MesurePreventionDto>> {

    return this.apiConsequenceIdMesurePreventionGetAllByFormGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MesurePreventionDto>>) => r.body as Array<MesurePreventionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConsequenceIdMesurePreventionGetAllByFormGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGetAllByFormGet$Json$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<MesurePreventionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ConsequenceService.ApiConsequenceIdMesurePreventionGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MesurePreventionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConsequenceIdMesurePreventionGetAllByFormGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConsequenceIdMesurePreventionGetAllByFormGet$Json(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<MesurePreventionDto>> {

    return this.apiConsequenceIdMesurePreventionGetAllByFormGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MesurePreventionDto>>) => r.body as Array<MesurePreventionDto>)
    );
  }

}
