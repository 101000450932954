/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MesurePreventionDto } from '../models/mesure-prevention-dto';
import { SituationTravailWithDangersDto } from '../models/situation-travail-with-dangers-dto';

@Injectable({
  providedIn: 'root',
})
export class MesurePreventionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMesurePreventionGet
   */
  static readonly ApiMesurePreventionGetPath = '/api/MesurePrevention';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<MesurePreventionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MesurePreventionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<MesurePreventionDto>> {

    return this.apiMesurePreventionGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MesurePreventionDto>>) => r.body as Array<MesurePreventionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<MesurePreventionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MesurePreventionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<MesurePreventionDto>> {

    return this.apiMesurePreventionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MesurePreventionDto>>) => r.body as Array<MesurePreventionDto>)
    );
  }

  /**
   * Path part for operation apiMesurePreventionPost
   */
  static readonly ApiMesurePreventionPostPath = '/api/MesurePrevention';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionPost$Plain$Response(params?: {
    body?: MesurePreventionDto
  }): Observable<StrictHttpResponse<MesurePreventionDto>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MesurePreventionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionPost$Plain(params?: {
    body?: MesurePreventionDto
  }): Observable<MesurePreventionDto> {

    return this.apiMesurePreventionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MesurePreventionDto>) => r.body as MesurePreventionDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionPost$Json$Response(params?: {
    body?: MesurePreventionDto
  }): Observable<StrictHttpResponse<MesurePreventionDto>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MesurePreventionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionPost$Json(params?: {
    body?: MesurePreventionDto
  }): Observable<MesurePreventionDto> {

    return this.apiMesurePreventionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MesurePreventionDto>) => r.body as MesurePreventionDto)
    );
  }

  /**
   * Path part for operation apiMesurePreventionIdGet
   */
  static readonly ApiMesurePreventionIdGetPath = '/api/MesurePrevention/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<MesurePreventionDto>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MesurePreventionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionIdGet$Plain(params: {
    id: number;
  }): Observable<MesurePreventionDto> {

    return this.apiMesurePreventionIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MesurePreventionDto>) => r.body as MesurePreventionDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<MesurePreventionDto>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MesurePreventionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionIdGet$Json(params: {
    id: number;
  }): Observable<MesurePreventionDto> {

    return this.apiMesurePreventionIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MesurePreventionDto>) => r.body as MesurePreventionDto)
    );
  }

  /**
   * Path part for operation apiMesurePreventionIdPut
   */
  static readonly ApiMesurePreventionIdPutPath = '/api/MesurePrevention/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionIdPut$Plain$Response(params: {
    id: number;
    body?: MesurePreventionDto
  }): Observable<StrictHttpResponse<MesurePreventionDto>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MesurePreventionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionIdPut$Plain(params: {
    id: number;
    body?: MesurePreventionDto
  }): Observable<MesurePreventionDto> {

    return this.apiMesurePreventionIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MesurePreventionDto>) => r.body as MesurePreventionDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionIdPut$Json$Response(params: {
    id: number;
    body?: MesurePreventionDto
  }): Observable<StrictHttpResponse<MesurePreventionDto>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MesurePreventionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMesurePreventionIdPut$Json(params: {
    id: number;
    body?: MesurePreventionDto
  }): Observable<MesurePreventionDto> {

    return this.apiMesurePreventionIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MesurePreventionDto>) => r.body as MesurePreventionDto)
    );
  }

  /**
   * Path part for operation apiMesurePreventionIdDelete
   */
  static readonly ApiMesurePreventionIdDeletePath = '/api/MesurePrevention/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiMesurePreventionIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMesurePreventionGetWithParentLevelsGet
   */
  static readonly ApiMesurePreventionGetWithParentLevelsGetPath = '/api/MesurePrevention/GetWithParentLevels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionGetWithParentLevelsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGetWithParentLevelsGet$Plain$Response(params?: {
    ids?: Array<number>;
  }): Observable<StrictHttpResponse<Array<SituationTravailWithDangersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionGetWithParentLevelsGetPath, 'get');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailWithDangersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionGetWithParentLevelsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGetWithParentLevelsGet$Plain(params?: {
    ids?: Array<number>;
  }): Observable<Array<SituationTravailWithDangersDto>> {

    return this.apiMesurePreventionGetWithParentLevelsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailWithDangersDto>>) => r.body as Array<SituationTravailWithDangersDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMesurePreventionGetWithParentLevelsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGetWithParentLevelsGet$Json$Response(params?: {
    ids?: Array<number>;
  }): Observable<StrictHttpResponse<Array<SituationTravailWithDangersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MesurePreventionService.ApiMesurePreventionGetWithParentLevelsGetPath, 'get');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SituationTravailWithDangersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMesurePreventionGetWithParentLevelsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMesurePreventionGetWithParentLevelsGet$Json(params?: {
    ids?: Array<number>;
  }): Observable<Array<SituationTravailWithDangersDto>> {

    return this.apiMesurePreventionGetWithParentLevelsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SituationTravailWithDangersDto>>) => r.body as Array<SituationTravailWithDangersDto>)
    );
  }

}
