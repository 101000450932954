import { TypeForm } from './../type-form';
export class lastUpdateCompany {
    private _idCompany: number;
    private _companyNom: string;
    private _modificationDate: Date;
    private _createdFormTypes: TypeForm[]

    constructor({idCompany = -1, companyNom = "", modificationDate = null, createdFormTypes = []}){
      this._idCompany = idCompany
      this._companyNom = companyNom;
      this._modificationDate = modificationDate;
      this._createdFormTypes = createdFormTypes;
    }

    get idCompany(): number {
      return this._idCompany;
    }

    set idCompany(value: number) {
      this._idCompany = value;
    }

    get companyNom(): string {
      return this._companyNom;
    }

    set companyNom(value: string) {
      this._companyNom = value;
    }

    get modificationDate(): Date {
      return this._modificationDate;
    }

    set modificationDate(value: Date) {
      this._modificationDate = value;
    }

    get createdFormTypes(): TypeForm[] {
      return this._createdFormTypes
    }

    set createdFormTypes(value: TypeForm[]) {
      this._createdFormTypes = value
    }



  }
