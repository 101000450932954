<div class="lastSocieteWrapper" (click)="editItem()">
  <div class="lastSocieteColonnes">
    <div class="lastSocieteColonneNom">
      <img class="lastSocieteImg" src="../../../assets/icones/SOC0.svg">
      <label class="lastSocietelabelFonce">{{societe.companyNom}}</label>
    </div>
    <div class="lastSocieteColonnePermis">
      <div *ngFor="let docencours of societe.createdFormTypes">
        <img class="petiteIcone" src='{{ "../../../assets/icones/" + getDocumentImage(docencours,0) + ".svg" }}'>
      </div>
    </div>
    <div class="lastSocieteColonneDateModification">
      <label class="lastSocietelabelFonce">{{societe.modificationDate.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}}</label>
    </div>
  </div>
  <div class="lastSocieteActions">
    <button class="lastSocieteEditButton" mat-menu-item (click)="editItem()">
      <mat-icon class="lastSocieteEditIcon">edit</mat-icon>
    </button>
  </div>
</div>


