/* tslint:disable */
/* eslint-disable */
export enum TypeForm {
  Pp = 'pp',
  Pj = 'pj',
  Pf = 'pf',
  Pc = 'pc',
  Pe = 'pe',
  Ph = 'ph'
}
