/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsequenceDto } from '../models/consequence-dto';
import { RisqueDto } from '../models/risque-dto';
import { TypeForm } from '../models/type-form';

@Injectable({
  providedIn: 'root',
})
export class RisqueService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRisqueGet
   */
  static readonly ApiRisqueGetPath = '/api/Risque';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<RisqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RisqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<RisqueDto>> {

    return this.apiRisqueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RisqueDto>>) => r.body as Array<RisqueDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<RisqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RisqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<RisqueDto>> {

    return this.apiRisqueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RisqueDto>>) => r.body as Array<RisqueDto>)
    );
  }

  /**
   * Path part for operation apiRisquePost
   */
  static readonly ApiRisquePostPath = '/api/Risque';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisquePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisquePost$Plain$Response(params?: {
    body?: RisqueDto
  }): Observable<StrictHttpResponse<RisqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisquePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RisqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisquePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisquePost$Plain(params?: {
    body?: RisqueDto
  }): Observable<RisqueDto> {

    return this.apiRisquePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RisqueDto>) => r.body as RisqueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisquePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisquePost$Json$Response(params?: {
    body?: RisqueDto
  }): Observable<StrictHttpResponse<RisqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisquePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RisqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisquePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisquePost$Json(params?: {
    body?: RisqueDto
  }): Observable<RisqueDto> {

    return this.apiRisquePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RisqueDto>) => r.body as RisqueDto)
    );
  }

  /**
   * Path part for operation apiRisqueIdGet
   */
  static readonly ApiRisqueIdGetPath = '/api/Risque/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RisqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RisqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdGet$Plain(params: {
    id: number;
  }): Observable<RisqueDto> {

    return this.apiRisqueIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RisqueDto>) => r.body as RisqueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<RisqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RisqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdGet$Json(params: {
    id: number;
  }): Observable<RisqueDto> {

    return this.apiRisqueIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RisqueDto>) => r.body as RisqueDto)
    );
  }

  /**
   * Path part for operation apiRisqueIdPut
   */
  static readonly ApiRisqueIdPutPath = '/api/Risque/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisqueIdPut$Plain$Response(params: {
    id: number;
    body?: RisqueDto
  }): Observable<StrictHttpResponse<RisqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RisqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisqueIdPut$Plain(params: {
    id: number;
    body?: RisqueDto
  }): Observable<RisqueDto> {

    return this.apiRisqueIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RisqueDto>) => r.body as RisqueDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisqueIdPut$Json$Response(params: {
    id: number;
    body?: RisqueDto
  }): Observable<StrictHttpResponse<RisqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RisqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRisqueIdPut$Json(params: {
    id: number;
    body?: RisqueDto
  }): Observable<RisqueDto> {

    return this.apiRisqueIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RisqueDto>) => r.body as RisqueDto)
    );
  }

  /**
   * Path part for operation apiRisqueIdDelete
   */
  static readonly ApiRisqueIdDeletePath = '/api/Risque/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiRisqueIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRisqueIdConsequenceGet
   */
  static readonly ApiRisqueIdConsequenceGetPath = '/api/Risque/{id}/Consequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdConsequenceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<ConsequenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdConsequenceGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ConsequenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdConsequenceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGet$Plain(params: {
    id: number;
  }): Observable<Array<ConsequenceDto>> {

    return this.apiRisqueIdConsequenceGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ConsequenceDto>>) => r.body as Array<ConsequenceDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdConsequenceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<ConsequenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdConsequenceGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ConsequenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdConsequenceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGet$Json(params: {
    id: number;
  }): Observable<Array<ConsequenceDto>> {

    return this.apiRisqueIdConsequenceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ConsequenceDto>>) => r.body as Array<ConsequenceDto>)
    );
  }

  /**
   * Path part for operation apiRisqueIdConsequenceGetAllByFormGet
   */
  static readonly ApiRisqueIdConsequenceGetAllByFormGetPath = '/api/Risque/{id}/Consequence/GetAllByForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdConsequenceGetAllByFormGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGetAllByFormGet$Plain$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<ConsequenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdConsequenceGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ConsequenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdConsequenceGetAllByFormGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGetAllByFormGet$Plain(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<ConsequenceDto>> {

    return this.apiRisqueIdConsequenceGetAllByFormGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ConsequenceDto>>) => r.body as Array<ConsequenceDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRisqueIdConsequenceGetAllByFormGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGetAllByFormGet$Json$Response(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<StrictHttpResponse<Array<ConsequenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RisqueService.ApiRisqueIdConsequenceGetAllByFormGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('typeForm', params.typeForm, {});
      rb.query('formId', params.formId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ConsequenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRisqueIdConsequenceGetAllByFormGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRisqueIdConsequenceGetAllByFormGet$Json(params: {
    id: number;
    typeForm?: TypeForm;
    formId?: number;
  }): Observable<Array<ConsequenceDto>> {

    return this.apiRisqueIdConsequenceGetAllByFormGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ConsequenceDto>>) => r.body as Array<ConsequenceDto>)
    );
  }

}
