/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SpConfinementDto } from '../models/sp-confinement-dto';

@Injectable({
  providedIn: 'root',
})
export class PermisSpecifiqueEspacesConfinesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPermisSpecifiqueEspacesConfinesGet
   */
  static readonly ApiPermisSpecifiqueEspacesConfinesGetPath = '/api/PermisSpecifiqueEspacesConfines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpConfinementDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpConfinementDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpConfinementDto>> {

    return this.apiPermisSpecifiqueEspacesConfinesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpConfinementDto>>) => r.body as Array<SpConfinementDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpConfinementDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpConfinementDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpConfinementDto>> {

    return this.apiPermisSpecifiqueEspacesConfinesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpConfinementDto>>) => r.body as Array<SpConfinementDto>)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueEspacesConfinesPost
   */
  static readonly ApiPermisSpecifiqueEspacesConfinesPostPath = '/api/PermisSpecifiqueEspacesConfines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesPost$Plain$Response(params?: {
    body?: SpConfinementDto
  }): Observable<StrictHttpResponse<SpConfinementDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConfinementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesPost$Plain(params?: {
    body?: SpConfinementDto
  }): Observable<SpConfinementDto> {

    return this.apiPermisSpecifiqueEspacesConfinesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpConfinementDto>) => r.body as SpConfinementDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesPost$Json$Response(params?: {
    body?: SpConfinementDto
  }): Observable<StrictHttpResponse<SpConfinementDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConfinementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesPost$Json(params?: {
    body?: SpConfinementDto
  }): Observable<SpConfinementDto> {

    return this.apiPermisSpecifiqueEspacesConfinesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpConfinementDto>) => r.body as SpConfinementDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueEspacesConfinesIdReportGet
   */
  static readonly ApiPermisSpecifiqueEspacesConfinesIdReportGetPath = '/api/PermisSpecifiqueEspacesConfines/{id}/Report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesIdReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdReportGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesIdReportGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesIdReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdReportGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiPermisSpecifiqueEspacesConfinesIdReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueEspacesConfinesIdGet
   */
  static readonly ApiPermisSpecifiqueEspacesConfinesIdGetPath = '/api/PermisSpecifiqueEspacesConfines/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpConfinementDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConfinementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdGet$Plain(params: {
    id: number;
  }): Observable<SpConfinementDto> {

    return this.apiPermisSpecifiqueEspacesConfinesIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpConfinementDto>) => r.body as SpConfinementDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpConfinementDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConfinementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdGet$Json(params: {
    id: number;
  }): Observable<SpConfinementDto> {

    return this.apiPermisSpecifiqueEspacesConfinesIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpConfinementDto>) => r.body as SpConfinementDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueEspacesConfinesIdPut
   */
  static readonly ApiPermisSpecifiqueEspacesConfinesIdPutPath = '/api/PermisSpecifiqueEspacesConfines/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesIdPut$Plain$Response(params: {
    id: number;
    body?: SpConfinementDto
  }): Observable<StrictHttpResponse<SpConfinementDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConfinementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesIdPut$Plain(params: {
    id: number;
    body?: SpConfinementDto
  }): Observable<SpConfinementDto> {

    return this.apiPermisSpecifiqueEspacesConfinesIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpConfinementDto>) => r.body as SpConfinementDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesIdPut$Json$Response(params: {
    id: number;
    body?: SpConfinementDto
  }): Observable<StrictHttpResponse<SpConfinementDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConfinementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueEspacesConfinesIdPut$Json(params: {
    id: number;
    body?: SpConfinementDto
  }): Observable<SpConfinementDto> {

    return this.apiPermisSpecifiqueEspacesConfinesIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpConfinementDto>) => r.body as SpConfinementDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueEspacesConfinesIdDelete
   */
  static readonly ApiPermisSpecifiqueEspacesConfinesIdDeletePath = '/api/PermisSpecifiqueEspacesConfines/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueEspacesConfinesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueEspacesConfinesService.ApiPermisSpecifiqueEspacesConfinesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueEspacesConfinesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueEspacesConfinesIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiPermisSpecifiqueEspacesConfinesIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
