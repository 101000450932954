/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AlertService } from './services/alert.service';
import { CompanyService } from './services/company.service';
import { ConsequenceService } from './services/consequence.service';
import { DangerService } from './services/danger.service';
import { DocumentAEnvoyerService } from './services/document-a-envoyer.service';
import { DocumentJustificatifService } from './services/document-justificatif.service';
import { DocumentPhysiqueService } from './services/document-physique.service';
import { DonneurOrdreService } from './services/donneur-ordre.service';
import { EmployeeService } from './services/employee.service';
import { EntityService } from './services/entity.service';
import { FormService } from './services/form.service';
import { InterventionAreaService } from './services/intervention-area.service';
import { LogService } from './services/log.service';
import { MesurePreventionService } from './services/mesure-prevention.service';
import { PermisJournalierService } from './services/permis-journalier.service';
import { PermisSpecifiqueConsignationService } from './services/permis-specifique-consignation.service';
import { PermisSpecifiqueEspacesConfinesService } from './services/permis-specifique-espaces-confines.service';
import { PermisSpecifiqueFeuService } from './services/permis-specifique-feu.service';
import { PermisSpecifiqueTravailHauteurService } from './services/permis-specifique-travail-hauteur.service';
import { PlansPreventionService } from './services/plans-prevention.service';
import { RisqueService } from './services/risque.service';
import { SituationTravailService } from './services/situation-travail.service';
import { UserService } from './services/user.service';
import { UserRoleService } from './services/user-role.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AlertService,
    CompanyService,
    ConsequenceService,
    DangerService,
    DocumentAEnvoyerService,
    DocumentJustificatifService,
    DocumentPhysiqueService,
    DonneurOrdreService,
    EmployeeService,
    EntityService,
    FormService,
    InterventionAreaService,
    LogService,
    MesurePreventionService,
    PermisJournalierService,
    PermisSpecifiqueConsignationService,
    PermisSpecifiqueEspacesConfinesService,
    PermisSpecifiqueFeuService,
    PermisSpecifiqueTravailHauteurService,
    PlansPreventionService,
    RisqueService,
    SituationTravailService,
    UserService,
    UserRoleService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
