import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lu-dropdown',
  templateUrl: './lu-dropdown.component.html',
  styleUrls: ['./lu-dropdown.component.css']
})
export class LuDropdownComponent implements OnInit {
  @Input() PlaceHolder = '';
  @Input() Items:any[] = [];
  @Output() change = new EventEmitter();

  selectedItem: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  onChangeValue() {
    this.change.emit(this.selectedItem);
  }

}
