/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SpHauteurDto } from '../models/sp-hauteur-dto';

@Injectable({
  providedIn: 'root',
})
export class PermisSpecifiqueTravailHauteurService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPermisSpecifiqueTravailHauteurGet
   */
  static readonly ApiPermisSpecifiqueTravailHauteurGetPath = '/api/PermisSpecifiqueTravailHauteur';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpHauteurDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpHauteurDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpHauteurDto>> {

    return this.apiPermisSpecifiqueTravailHauteurGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpHauteurDto>>) => r.body as Array<SpHauteurDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpHauteurDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpHauteurDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpHauteurDto>> {

    return this.apiPermisSpecifiqueTravailHauteurGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpHauteurDto>>) => r.body as Array<SpHauteurDto>)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueTravailHauteurPost
   */
  static readonly ApiPermisSpecifiqueTravailHauteurPostPath = '/api/PermisSpecifiqueTravailHauteur';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurPost$Plain$Response(params?: {
    body?: SpHauteurDto
  }): Observable<StrictHttpResponse<SpHauteurDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpHauteurDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurPost$Plain(params?: {
    body?: SpHauteurDto
  }): Observable<SpHauteurDto> {

    return this.apiPermisSpecifiqueTravailHauteurPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpHauteurDto>) => r.body as SpHauteurDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurPost$Json$Response(params?: {
    body?: SpHauteurDto
  }): Observable<StrictHttpResponse<SpHauteurDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpHauteurDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurPost$Json(params?: {
    body?: SpHauteurDto
  }): Observable<SpHauteurDto> {

    return this.apiPermisSpecifiqueTravailHauteurPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpHauteurDto>) => r.body as SpHauteurDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueTravailHauteurIdReportGet
   */
  static readonly ApiPermisSpecifiqueTravailHauteurIdReportGetPath = '/api/PermisSpecifiqueTravailHauteur/{id}/Report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurIdReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdReportGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurIdReportGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurIdReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdReportGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiPermisSpecifiqueTravailHauteurIdReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueTravailHauteurIdGet
   */
  static readonly ApiPermisSpecifiqueTravailHauteurIdGetPath = '/api/PermisSpecifiqueTravailHauteur/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpHauteurDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpHauteurDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdGet$Plain(params: {
    id: number;
  }): Observable<SpHauteurDto> {

    return this.apiPermisSpecifiqueTravailHauteurIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpHauteurDto>) => r.body as SpHauteurDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpHauteurDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpHauteurDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdGet$Json(params: {
    id: number;
  }): Observable<SpHauteurDto> {

    return this.apiPermisSpecifiqueTravailHauteurIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpHauteurDto>) => r.body as SpHauteurDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueTravailHauteurIdPut
   */
  static readonly ApiPermisSpecifiqueTravailHauteurIdPutPath = '/api/PermisSpecifiqueTravailHauteur/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurIdPut$Plain$Response(params: {
    id: number;
    body?: SpHauteurDto
  }): Observable<StrictHttpResponse<SpHauteurDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpHauteurDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurIdPut$Plain(params: {
    id: number;
    body?: SpHauteurDto
  }): Observable<SpHauteurDto> {

    return this.apiPermisSpecifiqueTravailHauteurIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpHauteurDto>) => r.body as SpHauteurDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurIdPut$Json$Response(params: {
    id: number;
    body?: SpHauteurDto
  }): Observable<StrictHttpResponse<SpHauteurDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpHauteurDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueTravailHauteurIdPut$Json(params: {
    id: number;
    body?: SpHauteurDto
  }): Observable<SpHauteurDto> {

    return this.apiPermisSpecifiqueTravailHauteurIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpHauteurDto>) => r.body as SpHauteurDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueTravailHauteurIdDelete
   */
  static readonly ApiPermisSpecifiqueTravailHauteurIdDeletePath = '/api/PermisSpecifiqueTravailHauteur/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueTravailHauteurIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueTravailHauteurService.ApiPermisSpecifiqueTravailHauteurIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueTravailHauteurIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueTravailHauteurIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiPermisSpecifiqueTravailHauteurIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
