/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentJustificatifDto } from '../models/document-justificatif-dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentJustificatifService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiDocumentJustificatifGet
   */
  static readonly ApiDocumentJustificatifGetPath = '/api/DocumentJustificatif';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DocumentJustificatifDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentJustificatifDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DocumentJustificatifDto>> {

    return this.apiDocumentJustificatifGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentJustificatifDto>>) => r.body as Array<DocumentJustificatifDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DocumentJustificatifDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentJustificatifDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DocumentJustificatifDto>> {

    return this.apiDocumentJustificatifGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentJustificatifDto>>) => r.body as Array<DocumentJustificatifDto>)
    );
  }

  /**
   * Path part for operation apiDocumentJustificatifPost
   */
  static readonly ApiDocumentJustificatifPostPath = '/api/DocumentJustificatif';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifPost$Plain$Response(params?: {
    body?: DocumentJustificatifDto
  }): Observable<StrictHttpResponse<DocumentJustificatifDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentJustificatifDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifPost$Plain(params?: {
    body?: DocumentJustificatifDto
  }): Observable<DocumentJustificatifDto> {

    return this.apiDocumentJustificatifPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentJustificatifDto>) => r.body as DocumentJustificatifDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifPost$Json$Response(params?: {
    body?: DocumentJustificatifDto
  }): Observable<StrictHttpResponse<DocumentJustificatifDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentJustificatifDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifPost$Json(params?: {
    body?: DocumentJustificatifDto
  }): Observable<DocumentJustificatifDto> {

    return this.apiDocumentJustificatifPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentJustificatifDto>) => r.body as DocumentJustificatifDto)
    );
  }

  /**
   * Path part for operation apiDocumentJustificatifIdGet
   */
  static readonly ApiDocumentJustificatifIdGetPath = '/api/DocumentJustificatif/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DocumentJustificatifDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentJustificatifDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifIdGet$Plain(params: {
    id: number;
  }): Observable<DocumentJustificatifDto> {

    return this.apiDocumentJustificatifIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentJustificatifDto>) => r.body as DocumentJustificatifDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DocumentJustificatifDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentJustificatifDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifIdGet$Json(params: {
    id: number;
  }): Observable<DocumentJustificatifDto> {

    return this.apiDocumentJustificatifIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentJustificatifDto>) => r.body as DocumentJustificatifDto)
    );
  }

  /**
   * Path part for operation apiDocumentJustificatifIdPut
   */
  static readonly ApiDocumentJustificatifIdPutPath = '/api/DocumentJustificatif/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifIdPut$Plain$Response(params: {
    id: number;
    body?: DocumentJustificatifDto
  }): Observable<StrictHttpResponse<DocumentJustificatifDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentJustificatifDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifIdPut$Plain(params: {
    id: number;
    body?: DocumentJustificatifDto
  }): Observable<DocumentJustificatifDto> {

    return this.apiDocumentJustificatifIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentJustificatifDto>) => r.body as DocumentJustificatifDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifIdPut$Json$Response(params: {
    id: number;
    body?: DocumentJustificatifDto
  }): Observable<StrictHttpResponse<DocumentJustificatifDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentJustificatifDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDocumentJustificatifIdPut$Json(params: {
    id: number;
    body?: DocumentJustificatifDto
  }): Observable<DocumentJustificatifDto> {

    return this.apiDocumentJustificatifIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentJustificatifDto>) => r.body as DocumentJustificatifDto)
    );
  }

  /**
   * Path part for operation apiDocumentJustificatifIdDelete
   */
  static readonly ApiDocumentJustificatifIdDeletePath = '/api/DocumentJustificatif/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDocumentJustificatifIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentJustificatifService.ApiDocumentJustificatifIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDocumentJustificatifIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDocumentJustificatifIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiDocumentJustificatifIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
