/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PermisJournalierDto } from '../models/permis-journalier-dto';

@Injectable({
  providedIn: 'root',
})
export class PermisJournalierService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPermisJournalierGet
   */
  static readonly ApiPermisJournalierGetPath = '/api/PermisJournalier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<PermisJournalierDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PermisJournalierDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<PermisJournalierDto>> {

    return this.apiPermisJournalierGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PermisJournalierDto>>) => r.body as Array<PermisJournalierDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<PermisJournalierDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PermisJournalierDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<PermisJournalierDto>> {

    return this.apiPermisJournalierGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PermisJournalierDto>>) => r.body as Array<PermisJournalierDto>)
    );
  }

  /**
   * Path part for operation apiPermisJournalierPost
   */
  static readonly ApiPermisJournalierPostPath = '/api/PermisJournalier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierPost$Plain$Response(params?: {
    body?: PermisJournalierDto
  }): Observable<StrictHttpResponse<PermisJournalierDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PermisJournalierDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierPost$Plain(params?: {
    body?: PermisJournalierDto
  }): Observable<PermisJournalierDto> {

    return this.apiPermisJournalierPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PermisJournalierDto>) => r.body as PermisJournalierDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierPost$Json$Response(params?: {
    body?: PermisJournalierDto
  }): Observable<StrictHttpResponse<PermisJournalierDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PermisJournalierDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierPost$Json(params?: {
    body?: PermisJournalierDto
  }): Observable<PermisJournalierDto> {

    return this.apiPermisJournalierPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PermisJournalierDto>) => r.body as PermisJournalierDto)
    );
  }

  /**
   * Path part for operation apiPermisJournalierIdReportGet
   */
  static readonly ApiPermisJournalierIdReportGetPath = '/api/PermisJournalier/{id}/Report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierIdReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdReportGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierIdReportGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierIdReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdReportGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiPermisJournalierIdReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiPermisJournalierIdGet
   */
  static readonly ApiPermisJournalierIdGetPath = '/api/PermisJournalier/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<PermisJournalierDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PermisJournalierDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdGet$Plain(params: {
    id: number;
  }): Observable<PermisJournalierDto> {

    return this.apiPermisJournalierIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PermisJournalierDto>) => r.body as PermisJournalierDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<PermisJournalierDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PermisJournalierDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdGet$Json(params: {
    id: number;
  }): Observable<PermisJournalierDto> {

    return this.apiPermisJournalierIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PermisJournalierDto>) => r.body as PermisJournalierDto)
    );
  }

  /**
   * Path part for operation apiPermisJournalierIdPut
   */
  static readonly ApiPermisJournalierIdPutPath = '/api/PermisJournalier/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierIdPut$Plain$Response(params: {
    id: number;
    body?: PermisJournalierDto
  }): Observable<StrictHttpResponse<PermisJournalierDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PermisJournalierDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierIdPut$Plain(params: {
    id: number;
    body?: PermisJournalierDto
  }): Observable<PermisJournalierDto> {

    return this.apiPermisJournalierIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PermisJournalierDto>) => r.body as PermisJournalierDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierIdPut$Json$Response(params: {
    id: number;
    body?: PermisJournalierDto
  }): Observable<StrictHttpResponse<PermisJournalierDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PermisJournalierDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisJournalierIdPut$Json(params: {
    id: number;
    body?: PermisJournalierDto
  }): Observable<PermisJournalierDto> {

    return this.apiPermisJournalierIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PermisJournalierDto>) => r.body as PermisJournalierDto)
    );
  }

  /**
   * Path part for operation apiPermisJournalierIdDelete
   */
  static readonly ApiPermisJournalierIdDeletePath = '/api/PermisJournalier/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisJournalierIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermisJournalierService.ApiPermisJournalierIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisJournalierIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisJournalierIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiPermisJournalierIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
