/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DonneurOrdreDto } from '../models/donneur-ordre-dto';

@Injectable({
  providedIn: 'root',
})
export class DonneurOrdreService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiDonneurOrdreGet
   */
  static readonly ApiDonneurOrdreGetPath = '/api/DonneurOrdre';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdreGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DonneurOrdreDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdreGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DonneurOrdreDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdreGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DonneurOrdreDto>> {

    return this.apiDonneurOrdreGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DonneurOrdreDto>>) => r.body as Array<DonneurOrdreDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdreGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<DonneurOrdreDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdreGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DonneurOrdreDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdreGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<DonneurOrdreDto>> {

    return this.apiDonneurOrdreGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DonneurOrdreDto>>) => r.body as Array<DonneurOrdreDto>)
    );
  }

  /**
   * Path part for operation apiDonneurOrdrePost
   */
  static readonly ApiDonneurOrdrePostPath = '/api/DonneurOrdre';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdrePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdrePost$Plain$Response(params?: {
    body?: DonneurOrdreDto
  }): Observable<StrictHttpResponse<DonneurOrdreDto>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdrePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DonneurOrdreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdrePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdrePost$Plain(params?: {
    body?: DonneurOrdreDto
  }): Observable<DonneurOrdreDto> {

    return this.apiDonneurOrdrePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DonneurOrdreDto>) => r.body as DonneurOrdreDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdrePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdrePost$Json$Response(params?: {
    body?: DonneurOrdreDto
  }): Observable<StrictHttpResponse<DonneurOrdreDto>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdrePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DonneurOrdreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdrePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdrePost$Json(params?: {
    body?: DonneurOrdreDto
  }): Observable<DonneurOrdreDto> {

    return this.apiDonneurOrdrePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DonneurOrdreDto>) => r.body as DonneurOrdreDto)
    );
  }

  /**
   * Path part for operation apiDonneurOrdreIdGet
   */
  static readonly ApiDonneurOrdreIdGetPath = '/api/DonneurOrdre/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdreIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DonneurOrdreDto>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdreIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DonneurOrdreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdreIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreIdGet$Plain(params: {
    id: number;
  }): Observable<DonneurOrdreDto> {

    return this.apiDonneurOrdreIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DonneurOrdreDto>) => r.body as DonneurOrdreDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdreIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DonneurOrdreDto>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdreIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DonneurOrdreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdreIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreIdGet$Json(params: {
    id: number;
  }): Observable<DonneurOrdreDto> {

    return this.apiDonneurOrdreIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DonneurOrdreDto>) => r.body as DonneurOrdreDto)
    );
  }

  /**
   * Path part for operation apiDonneurOrdreIdPut
   */
  static readonly ApiDonneurOrdreIdPutPath = '/api/DonneurOrdre/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdreIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdreIdPut$Plain$Response(params: {
    id: number;
    body?: DonneurOrdreDto
  }): Observable<StrictHttpResponse<DonneurOrdreDto>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdreIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DonneurOrdreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdreIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdreIdPut$Plain(params: {
    id: number;
    body?: DonneurOrdreDto
  }): Observable<DonneurOrdreDto> {

    return this.apiDonneurOrdreIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DonneurOrdreDto>) => r.body as DonneurOrdreDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdreIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdreIdPut$Json$Response(params: {
    id: number;
    body?: DonneurOrdreDto
  }): Observable<StrictHttpResponse<DonneurOrdreDto>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdreIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DonneurOrdreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdreIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDonneurOrdreIdPut$Json(params: {
    id: number;
    body?: DonneurOrdreDto
  }): Observable<DonneurOrdreDto> {

    return this.apiDonneurOrdreIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DonneurOrdreDto>) => r.body as DonneurOrdreDto)
    );
  }

  /**
   * Path part for operation apiDonneurOrdreIdDelete
   */
  static readonly ApiDonneurOrdreIdDeletePath = '/api/DonneurOrdre/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDonneurOrdreIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DonneurOrdreService.ApiDonneurOrdreIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDonneurOrdreIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDonneurOrdreIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiDonneurOrdreIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
