import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from './Pages/local-storage.service';
import { AuthService } from './shared/auth/auth.service';
import { UserDto } from './shared/models';
import { UserService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'PEPS';
  ngUnsubscribe = new Subject();
  user: UserDto;
  login :string ='';
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor( private userService: UserService,
    private localStorageService: LocalStorageService,
               private router: Router,
               private authService: AuthService,

    ) {
      this.authService.isLoggedIn$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(i => this.isLoggedIn$.next(i))
    }


ngOnInit() {

  this.user = JSON.parse( this.localStorageService.getItem('user'));

  if(this.user!= null){
   this.login = this.user.login;
  }

}


public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}



logout(){
  // this.localStorageService.removeItem('userToken');
  // this.localStorageService.removeItem('user');
  this.authService.logout();

  this.router.navigateByUrl('/pages/login');
}

}
