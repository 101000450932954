<div class="lastDocumentWrapper"  (click)="editItem()">
  <div class="lastDocumentColonnes">
    <div class="lastDocumentColonneInfos">
      <img class="lastDocumentImgDocument" src='{{ "../../../assets/icones/" + getDocumentImage(document.typeForm,0) + ".svg" }}'>
      <div class="lastDocumentColonneInfosLabels">
          <label class="lastDocumentlabelFonce mb-0">{{typeDocumentEnClair(document.typeForm) }}</label>
          <label class="lastDocumentlabelClair mb-0">{{document.intervention}}</label>
      </div>
    </div>
    <div class="lastDocumentColonneDateIntervention lastDocumentColonneInfosLabels">
      <label class="lastDocumentlabelFonce mb-0">{{(document.interventionDateDebut !== null ? document.interventionDateDebut.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }) : "")
                                    + " - " + (document.interventionDateFin !== null ? document.interventionDateFin.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' }) : "") }}</label>
      <label class="lastDocumentlabelClair mb-0">{{document.interventionAreaName}}</label>
    </div>
    <div class="lastDocumentColonneDateModification lastDocumentColonneInfosLabels">
      <label class="lastDocumentlabelFonce mb-0">{{document.modificationDate.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}}</label>
      <label class="lastDocumentlabelClair mb-0">{{document.donneurOrdreName}}</label>
    </div>
  </div>
  <div class="lastDocumentActions">
    <button class="lastDocumentEditButton" mat-menu-item (click)="editItem()">
      <mat-icon class="lastDocumentEditIcon">edit</mat-icon>
    </button>
  </div>
</div>
