/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanyDto } from '../models/company-dto';
import { CompanySearchResultDto } from '../models/company-search-result-dto';
import { DuplicateCompanyCityResponsible } from '../models/duplicate-company-city-responsible';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCompanyGet
   */
  static readonly ApiCompanyGetPath = '/api/Company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<CompanyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<CompanyDto>> {

    return this.apiCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyDto>>) => r.body as Array<CompanyDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<CompanyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<CompanyDto>> {

    return this.apiCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyDto>>) => r.body as Array<CompanyDto>)
    );
  }

  /**
   * Path part for operation apiCompanyPost
   */
  static readonly ApiCompanyPostPath = '/api/Company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyPost$Plain$Response(params?: {
    body?: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyPost$Plain(params?: {
    body?: CompanyDto
  }): Observable<CompanyDto> {

    return this.apiCompanyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyPost$Json$Response(params?: {
    body?: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyPost$Json(params?: {
    body?: CompanyDto
  }): Observable<CompanyDto> {

    return this.apiCompanyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation apiCompanyIdGet
   */
  static readonly ApiCompanyIdGetPath = '/api/Company/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdGet$Plain(params: {
    id: number;
  }): Observable<CompanyDto> {

    return this.apiCompanyIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdGet$Json(params: {
    id: number;
  }): Observable<CompanyDto> {

    return this.apiCompanyIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation apiCompanyIdPut
   */
  static readonly ApiCompanyIdPutPath = '/api/Company/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyIdPut$Plain$Response(params: {
    id: number;
    body?: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyIdPut$Plain(params: {
    id: number;
    body?: CompanyDto
  }): Observable<CompanyDto> {

    return this.apiCompanyIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyIdPut$Json$Response(params: {
    id: number;
    body?: CompanyDto
  }): Observable<StrictHttpResponse<CompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCompanyIdPut$Json(params: {
    id: number;
    body?: CompanyDto
  }): Observable<CompanyDto> {

    return this.apiCompanyIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyDto>) => r.body as CompanyDto)
    );
  }

  /**
   * Path part for operation apiCompanyIdDelete
   */
  static readonly ApiCompanyIdDeletePath = '/api/Company/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiCompanyIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCompanyIdSalariesGet
   */
  static readonly ApiCompanyIdSalariesGetPath = '/api/Company/{id}/Salaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyIdSalariesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdSalariesGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyIdSalariesGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyIdSalariesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyIdSalariesGet(params: {
    id: number;
  }): Observable<void> {

    return this.apiCompanyIdSalariesGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCompanySearchGet
   */
  static readonly ApiCompanySearchGetPath = '/api/Company/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanySearchGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchGet$Plain$Response(params?: {
    name?: string;
  }): Observable<StrictHttpResponse<Array<CompanySearchResultDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanySearchGetPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanySearchResultDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanySearchGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchGet$Plain(params?: {
    name?: string;
  }): Observable<Array<CompanySearchResultDto>> {

    return this.apiCompanySearchGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanySearchResultDto>>) => r.body as Array<CompanySearchResultDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanySearchGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchGet$Json$Response(params?: {
    name?: string;
  }): Observable<StrictHttpResponse<Array<CompanySearchResultDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanySearchGetPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanySearchResultDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanySearchGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanySearchGet$Json(params?: {
    name?: string;
  }): Observable<Array<CompanySearchResultDto>> {

    return this.apiCompanySearchGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanySearchResultDto>>) => r.body as Array<CompanySearchResultDto>)
    );
  }

  /**
   * Path part for operation apiCompanyDuplicateCompanyGet
   */
  static readonly ApiCompanyDuplicateCompanyGetPath = '/api/Company/DuplicateCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyDuplicateCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDuplicateCompanyGet$Plain$Response(params?: {
    name?: string;
  }): Observable<StrictHttpResponse<DuplicateCompanyCityResponsible>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyDuplicateCompanyGetPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateCompanyCityResponsible>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyDuplicateCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDuplicateCompanyGet$Plain(params?: {
    name?: string;
  }): Observable<DuplicateCompanyCityResponsible> {

    return this.apiCompanyDuplicateCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DuplicateCompanyCityResponsible>) => r.body as DuplicateCompanyCityResponsible)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCompanyDuplicateCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDuplicateCompanyGet$Json$Response(params?: {
    name?: string;
  }): Observable<StrictHttpResponse<DuplicateCompanyCityResponsible>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyService.ApiCompanyDuplicateCompanyGetPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateCompanyCityResponsible>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCompanyDuplicateCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCompanyDuplicateCompanyGet$Json(params?: {
    name?: string;
  }): Observable<DuplicateCompanyCityResponsible> {

    return this.apiCompanyDuplicateCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DuplicateCompanyCityResponsible>) => r.body as DuplicateCompanyCityResponsible)
    );
  }

}
