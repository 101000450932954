import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { LastUpdatesComponent } from './last-updates.component';
import { FiltreDocumentsComponent } from './filtre-documents/filtre-documents.component';
import { MaterialModule } from 'src/app/material.module';
import { VisuSocieteComponent } from './visu-societe/visu-societe.component';
import { VisuPermisComponent } from 'src/app/Pages/entreprise/visu-permis/visu-permis.component';
import { VisuDocumentComponent } from './visu-document/visu-document.component';
import { HeaderVisuDocumentComponent } from './header-visu-document/header-visu-document.component';
import { HeaderVisuSocieteComponent } from './header-visu-societe/header-visu-societe.component';

@NgModule({
  imports: [
    CommonModule,
    NgbButtonsModule,
    MaterialModule
  ],
  declarations: [
    LastUpdatesComponent,
    FiltreDocumentsComponent,
    VisuSocieteComponent,
    VisuDocumentComponent,
    HeaderVisuDocumentComponent,
    HeaderVisuSocieteComponent,
  ]
})
export class LastUpdatesModule { }
