import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LastUpdatesComponent } from './components/last-updates/last-updates.component';
import { NewDocumentComponent } from './components/new-document/new-document.component' ;
import { ParametersComponent } from './shared/parameters/parameters.component';
import { AuthGuard } from './shared/auth/auth.guard';

const routes: Routes = [
  {
    path:'',
    redirectTo:'lastupdates',
    pathMatch:'full'
  },
  {
    path: 'pages', loadChildren: () => import('src/app/Pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'lastupdates', component: LastUpdatesComponent, canActivate:[AuthGuard]
  },
  {
    path: 'newDocument', component: NewDocumentComponent
  },
  {
    path: 'params', component: ParametersComponent
  },


]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
