import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HandleErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private _snackBar: MatSnackBar) {}

  handleError(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 401:
          this.router.navigateByUrl('/pages/login');
          break;
          case 500:
            this._snackBar.open('Une erreur serveur est survenue', '', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            return throwError(error);
          default:
            return throwError(error);
      }
    }
    return throwError('Error not recognized');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((err) => this.handleError(err)));
  }
}
