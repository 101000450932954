<div class="headerSociete">
  <div class="headerSocieteColonnes">
    <div class="headerSocieteColonneNom">
      <label class="labelClair"></label>
    </div>
    <div class="headerSocieteColonnePermis">
      <label class="labelClair">Plans / Permis</label>
    </div>
    <div class="headerSocieteColonneDateModification">
      <label class="labelClair">Modifiée le</label>
    </div>
  </div>
</div>


