import { AlertComponent } from './components/alert.component';
import { NgModule } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import localeFr from '@angular/common/locales/fr';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';

import { LastUpdatesModule } from './components/last-updates/last-updates.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LuDatePickerComponent } from './shared/lu-date-picker/lu-date-picker.component';
import { LuDropdownComponent } from './shared/lu-dropdown/lu-dropdown.component';
import { NewDocumentComponent } from './components/new-document/new-document.component';
import { ParametersComponent } from './shared/parameters/parameters.component';
import { SpinnerOverlayComponent } from './shared/spinner-overlay/spinner-overlay.component';
import { SideMenuComponent } from './shared/side-menu/side-menu.component';
import { RegisterComponent } from './shared/register/register.component';
import { ApiModule } from './shared/api.module';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { AuthInterceptor } from './loginInterceptor/auth.interceptor';
import { AuthService } from './shared/auth/auth.service';
import { environment } from 'src/environments/environment';
import { SpinnerInterceptor } from './spinOverlayInterceptor/spinner-overlay.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MOMENT_DATE_FORMATS } from 'src/app/shared/utils/format_date';
import { NetworkInterceptor } from './networkInterceptor/network-interceptor.interceptor';
import { HandleErrorInterceptor } from './handleErrorInterceptor/handle-error.interceptor';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    LuDatePickerComponent,
    LuDropdownComponent,
    NewDocumentComponent,
    ParametersComponent,
    RegisterComponent,
    AlertComponent,
    ConfirmDialogComponent,
    SpinnerOverlayComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MaterialModule,
    MatNativeDateModule,
    LastUpdatesModule,
    ApiModule.forRoot({ rootUrl: environment.WebServiceUrl }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    AuthGuard,
    DatePipe,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
