<!--<div class="pageBox">
  <div class="headerGeneral">
    <label>PEPS</label>
  </div>
  <div class="articleGeneral">
    <div class="sidebar">
      <div class="spacer"> </div>
      <div><img class="imgSideBar" src="../assets/icones/Picto_historique.svg"></div>
      <div><img class="imgSideBar" src="../assets/icones/picto_ajouter.svg"></div>
      <div><img class="imgSideBar" src="../assets/icones/Picto_admin.svg"></div>
    </div>
    <div class="pageActive"><router-outlet></router-outlet></div>
  </div>
  <footer class="footerGeneral">
  </footer>
</div>-->
<!--<ul>
<li><a routerLink= "/addEntreprise">CREER UNE FICHE SOCIETE</a></li>
<li><a routerLink= "/addPermis">CREER UN PERMIS DE TRAVAIL GENERAL</a></li>
<li><a routerLink= "/addPermisConsignationElectrique">CREER UN PERMIS DE TRAVAIL SPECIFIQUE CONSIGNATION </a></li>
<li><a routerLink= "/addPermisLieuConfine">CREER UN PERMIS DE TRAVAIL SPECIFIQUE ESPACE CONFINE  </a> </li>
<li><a routerLink= "/addPermisHauteur">CREER UN PERMIS DE TRAVAIL SPECIFIQUE TRAVAIL EN HAUTEUR  </a> </li>
<li><a routerLink= "/addPermisFeu">CREER UN PERMIS DE TRAVAIL SPECIFIQUE FEU  </a> </li>
</ul>-->


<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Ubuntu" />

<!-- <div id="header">
  <div id="headerContent">
    <span class="app-title">PEPS</span>
    <div class="user-info">
      <div class="user-info-name">Nom User</div>
      <div class="user-info-company-role">User info company role</div>
      <div class="user-info-avatar"><img src="../assets/icones/user.svg"></div>
    </div>
  </div>
<div class="pictoUser">
  <div class="infoUser">
    <label>Nom User</label>
    <label>Titre User</label>
  </div>
  <div><img class="imgSideBar" src="../assets/icones/Picto_historique.svg"></div>
</div> -->


<nav class="navbar navbar-expand-lg navbar-light">
  <label class="titreApplication">PEPS</label>
  <ul class="nav navbar-nav flex-row justify-content-between ml-auto">
    <li class="nav-item" *ngIf="isLoggedIn$ | async as isLoggedIn">
      <a class="nav-link" href="#">{{login}}</a>
    </li>
    <li class="nav-item" *ngIf="isLoggedIn$ | async as isLoggedIn" >
      <a class="nav-link" (click)="logout()" >Logout</a>
    </li>
  </ul>
</nav>

<div id="page">
  <div id="sideBar">
    <!-- <div class="spacer"></div> -->
    <div class="topPicto"><a routerLink="/lastupdates"><img class="imgSideBar"
          src="../assets/icones/Picto_historique.svg"></a></div>
    <div><a routerLink="/newDocument"><img class="imgSideBar" src="../assets/icones/picto_ajouter.svg"></a></div>
    <div class="botPicto"><a routerLink="/params"><img class="imgSideBar" src="../assets/icones/Picto_admin.svg"></a>
    </div>
    <!--<div class="imgSideBar"><a routerLink="/pages/alerts"><img class="imgSideBar"
      src="../assets/icones/picto_alerte.svg"></a></div>-->
    <!-- <div><a routerLink= "/preventionPlan"><img class="imgSideBar" src="../assets/icones/PP.svg"></a></div>
    <div><a routerLink= "/plan"><img class="imgSideBar" src="../assets/icones/PJ.svg"></a></div>
    <div><a routerLink= "/planFire"><img class="imgSideBar" src="../assets/icones/PF.svg"></a></div>
    <div><a routerLink= "/planHeight"><img class="imgSideBar" src="../assets/icones/PH.svg"></a></div>
    <div><a routerLink= "/planLockdown"><img class="imgSideBar" src="../assets/icones/PEC.svg"></a></div>
    <div><a routerLink= "/planConsignation"><img class="imgSideBar" src="../assets/icones/PE.svg"></a></div> -->

  </div>
  <div id="content">
    <div class="pageActive">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
