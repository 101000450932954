<!-- <div class="pageNouveauDocument"> -->
<div class="container">
  <h3 class="new-document-title">Créer :</h3>
  <div class="d-flex justify-content-center">
    <div>
      <button class="boutonArrondiRouge" (click)="openPage(EnumNavigation.NewEntreprise)">
        <img src="../../../../assets/logos/ContractorManagement.png" title="Logo Contractor Management" class="logos-new-doc">
        <p>Une fiche entreprise</p>
      </button>
    </div>
    <div>
      <button class="boutonArrondiRouge" (click)="openPage(EnumNavigation.NewPlanPrevention)">
        <img src="../../../../assets/logos/ContractorManagement.png" title="Logo Contractor Management" class="logos-new-doc">
        <p>Un plan de prévention</p>
      </button>
    </div>

  </div>
  <div class="d-flex justify-content-center">
    <div>
      <button class="boutonArrondiRouge" (click)="openPage(EnumNavigation.NewPermisGeneral)">
        <img src="../../../../assets/logos/ContractorManagement.png" title="Logo Contractor Management" class="logos-new-doc">
        <p>Un permis journalier</p>
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div>
      <button class="boutonArrondiRouge" (click)="openPage(EnumNavigation.NewPermisFeu)">
        <img src="../../../../assets/logos/SupervisedHotWork.png" title="Logo Supervised Hot Work" class="logos-new-doc">
        <p>Un permis feu</p>
      </button>
    </div>
    <div>
      <button class="boutonArrondiRouge" (click)="openPage(EnumNavigation.NewPermisConfine)">
        <img src="../../../../assets/logos/RestrictedConfinedSpace.png" title="Logo Restricted Confined Space" class="logos-new-doc">
        <p>Un permis espaces confinés</p>
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div>
      <button class="boutonArrondiRouge" (click)="openPage(EnumNavigation.NewPermisElec)">
        <img src="../../../../assets/logos/LivePartsAccessForbidden.png" title="Logo Live Parts Access Forbidden" class="logos-new-doc">
        <p>Un permis consignation</p>
      </button>
    </div>
    <div>
      <button class="boutonArrondiRouge" (click)="openPage(EnumNavigation.NewPermisHauteur)">
        <img src="../../../../assets/logos/Above2m.png" title="Logo Above 2 m" class="logos-new-doc">
        <p>Un permis travail en hauteur</p>
      </button>
    </div>
  </div>
</div>
