/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SpConsignationDto } from '../models/sp-consignation-dto';

@Injectable({
  providedIn: 'root',
})
export class PermisSpecifiqueConsignationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPermisSpecifiqueConsignationGet
   */
  static readonly ApiPermisSpecifiqueConsignationGetPath = '/api/PermisSpecifiqueConsignation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationGet$Plain$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpConsignationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpConsignationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationGet$Plain(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpConsignationDto>> {

    return this.apiPermisSpecifiqueConsignationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpConsignationDto>>) => r.body as Array<SpConsignationDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationGet$Json$Response(params?: {
    index?: number;
    count?: number;
  }): Observable<StrictHttpResponse<Array<SpConsignationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationGetPath, 'get');
    if (params) {
      rb.query('index', params.index, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SpConsignationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationGet$Json(params?: {
    index?: number;
    count?: number;
  }): Observable<Array<SpConsignationDto>> {

    return this.apiPermisSpecifiqueConsignationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SpConsignationDto>>) => r.body as Array<SpConsignationDto>)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueConsignationPost
   */
  static readonly ApiPermisSpecifiqueConsignationPostPath = '/api/PermisSpecifiqueConsignation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationPost$Plain$Response(params?: {
    body?: SpConsignationDto
  }): Observable<StrictHttpResponse<SpConsignationDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConsignationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationPost$Plain(params?: {
    body?: SpConsignationDto
  }): Observable<SpConsignationDto> {

    return this.apiPermisSpecifiqueConsignationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpConsignationDto>) => r.body as SpConsignationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationPost$Json$Response(params?: {
    body?: SpConsignationDto
  }): Observable<StrictHttpResponse<SpConsignationDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConsignationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationPost$Json(params?: {
    body?: SpConsignationDto
  }): Observable<SpConsignationDto> {

    return this.apiPermisSpecifiqueConsignationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpConsignationDto>) => r.body as SpConsignationDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueConsignationIdReportGet
   */
  static readonly ApiPermisSpecifiqueConsignationIdReportGetPath = '/api/PermisSpecifiqueConsignation/{id}/Report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationIdReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdReportGet$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationIdReportGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationIdReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdReportGet(params: {
    id: number;
  }): Observable<string> {

    return this.apiPermisSpecifiqueConsignationIdReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueConsignationIdGet
   */
  static readonly ApiPermisSpecifiqueConsignationIdGetPath = '/api/PermisSpecifiqueConsignation/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdGet$Plain$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpConsignationDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConsignationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdGet$Plain(params: {
    id: number;
  }): Observable<SpConsignationDto> {

    return this.apiPermisSpecifiqueConsignationIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpConsignationDto>) => r.body as SpConsignationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdGet$Json$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<SpConsignationDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConsignationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdGet$Json(params: {
    id: number;
  }): Observable<SpConsignationDto> {

    return this.apiPermisSpecifiqueConsignationIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpConsignationDto>) => r.body as SpConsignationDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueConsignationIdPut
   */
  static readonly ApiPermisSpecifiqueConsignationIdPutPath = '/api/PermisSpecifiqueConsignation/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationIdPut$Plain$Response(params: {
    id: number;
    body?: SpConsignationDto
  }): Observable<StrictHttpResponse<SpConsignationDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConsignationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationIdPut$Plain(params: {
    id: number;
    body?: SpConsignationDto
  }): Observable<SpConsignationDto> {

    return this.apiPermisSpecifiqueConsignationIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SpConsignationDto>) => r.body as SpConsignationDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationIdPut$Json$Response(params: {
    id: number;
    body?: SpConsignationDto
  }): Observable<StrictHttpResponse<SpConsignationDto>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SpConsignationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPermisSpecifiqueConsignationIdPut$Json(params: {
    id: number;
    body?: SpConsignationDto
  }): Observable<SpConsignationDto> {

    return this.apiPermisSpecifiqueConsignationIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SpConsignationDto>) => r.body as SpConsignationDto)
    );
  }

  /**
   * Path part for operation apiPermisSpecifiqueConsignationIdDelete
   */
  static readonly ApiPermisSpecifiqueConsignationIdDeletePath = '/api/PermisSpecifiqueConsignation/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPermisSpecifiqueConsignationIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermisSpecifiqueConsignationService.ApiPermisSpecifiqueConsignationIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPermisSpecifiqueConsignationIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPermisSpecifiqueConsignationIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiPermisSpecifiqueConsignationIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
